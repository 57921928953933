import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { setUser } from "../../redux/actions/user";
import { useDispatch } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .post(
        `${server}/user/login-user`,
        {
          email,
          password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Login Success!");
        const { user, hasActiveSubscription, subscriptionShown } = res.data;
        dispatch(setUser(user));
        if (!subscriptionShown && !hasActiveSubscription) {
          navigate("/subscriptions");
        } else {
          navigate("/home"); 
          window.location.reload(true);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="login-component-container">
      <div className="login-component-header">
        <h2 className="login-component-title">
          Login to your account
        </h2>
      </div>
      <div className="login-component-form-container">
        <div className="login-component-form-wrapper">
          <form className="login-component-form" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="login-component-label"
              >
                Email address
              </label>
              <div className="login-component-input-wrapper">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="login-component-input"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="login-component-label"
              >
                Password
              </label>
              <div className="login-component-input-wrapper relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="login-component-input"
                />
                {visible ? (
                  <AiOutlineEye
                    className="login-component-icon"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="login-component-icon"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>
            <div className="login-component-options">
              <div className="login-component-remember-me">
                <input
                  type="checkbox"
                  name="remember-me"
                  id="remember-me"
                  className="login-component-checkbox"
                />
                <label
                  htmlFor="remember-me"
                  className="login-component-checkbox-label"
                >
                  Remember me
                </label>
              </div>
              <div className="login-component-forgot-password">
                <a
                  href="/forgot-password"
                  className="login-component-forgot-password-link"
                >
                  Forgot your password?
                </a>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="login-component-submit-button"
              >
                Submit
              </button>
            </div>
            <div className="login-component-signup-link">
              <h4>Not registered?</h4>
              <Link to="/signup" className="login-component-signup-text">
                Sign Up
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;


