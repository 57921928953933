import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { server } from "../../server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LifeEvents = ({ ownerId, profileType }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [eventDetails, setEventDetails] = useState({ date: "", description: "" });
  const [lifeEvents, setLifeEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const lifeEventOptions = [
    "Birthday",
    "Celebrations",
    "Marriage/Engagement",
    "Graduation",
    "Anniversary",
    "New Job",
    "New Home",
    "Retirement",
    "Other",
  ];

  const toggleDropdown = (e) => {
    const rect = e.target.getBoundingClientRect();
    setDropdownPosition({
      top: rect.bottom + window.scrollY + 5, // Position below the button
      left: rect.left + window.scrollX,
      width: rect.width,
    });
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
    setIsDropdownOpen(false);
    setShowModal(true); // Show the modal when an event type is selected
  };

  const closeModal = () => {
    setShowModal(false);
    setEventDetails({ date: "", description: "" });
    setSelectedEvent(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${server}/lifeEvents/${profileType.toLowerCase()}/${ownerId}/life-events`,
        {
          eventType: selectedEvent,
          date: eventDetails.date,
          description: eventDetails.description,
        }
      );
      if (response.data.success) {
        setLifeEvents((prevEvents) => [response.data.lifeEvent, ...prevEvents]);
        closeModal();
        toast.success("Life event created successfully!");
      } else {
        setError(response.data.message || "Failed to create life event.");
      }
    } catch (error) {
      console.error("Error creating life event:", error);
      setError("Failed to create life event. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchLifeEvents = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${server}/lifeEvents/${profileType.toLowerCase()}/${ownerId}/life-events`);
      if (response.data.success) {
        setLifeEvents(response.data.lifeEvents);
      } else {
        setError(response.data.message || "Failed to fetch life events.");
      }
    } catch (error) {
      console.error("Error fetching life events:", error);
      setError("Failed to fetch life events. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ownerId && profileType) {
      fetchLifeEvents();
    }
  }, [ownerId, profileType]);

  return (
    <div className="life-events-component">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />

      <button className="create-life-event-button" onClick={toggleDropdown}>
        Create a Life Event
        <span className={`arrow ${isDropdownOpen ? "up" : "down"}`}></span>
      </button>

      {/* Render Dropdown as a Portal */}
      {isDropdownOpen &&
        ReactDOM.createPortal(
          <ul
            className="life-events-dropdown"
            style={{
              position: "absolute",
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              width: dropdownPosition.width,
            }}
          >
            {lifeEventOptions.map((event, index) => (
              <li
                key={index}
                className="life-events-dropdown-item"
                onClick={() => handleEventSelect(event)}
              >
                {event}
              </li>
            ))}
          </ul>,
          document.body
        )}

      {/* Display Existing Life Events */}
      <div className="life-events-list">
        {loading && <div className="life-events-loading">Loading...</div>}
        {error && <div className="life-events-error">{error}</div>}
        {lifeEvents.length > 0 ? (
          <ul className="life-events-ul">
            {lifeEvents.map((event) => (
              <li key={event._id} className="life-event-item">
                <h4>{event.eventType}</h4>
                <p>{new Date(event.date).toLocaleDateString()}</p>
                <p>{event.description}</p>
              </li>
            ))}
          </ul>
        ) : (
          !loading && <p className="life-events-no-events">No life events found.</p>
        )}
      </div>

      {/* Modal for Creating Life Event */}
      {showModal &&
        ReactDOM.createPortal(
          <div className="life-events-modal-overlay" onClick={closeModal}>
            <div className="life-events-modal-content" onClick={(e) => e.stopPropagation()}>
              <span className="life-events-close-button" onClick={closeModal}>
                &times;
              </span>
              <h2>Create a {selectedEvent}</h2>
              <form onSubmit={handleSubmit} className="life-events-form">
                <label>
                  Date:
                  <input
                    type="date"
                    name="date"
                    value={eventDetails.date}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Description:
                  <textarea
                    name="description"
                    value={eventDetails.description}
                    onChange={handleChange}
                    required
                  ></textarea>
                </label>
                <button type="submit" className="life-events-submit-button" disabled={loading}>
                  {loading ? "Creating..." : "Create Event"}
                </button>
                {error && <p className="life-events-form-error">{error}</p>}
              </form>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default LifeEvents;


