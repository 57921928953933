import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../../server';
import MyEventsByOwner from './MyEventsByOwner';
import MyEventsProps from './MyEventsProps';

const tabs = [
  { name: 'Created Events', key: 'created' },
  { name: 'Attending Events', key: 'attending' },
];

const EventsParentContainer = ({ ownerId }) => {
  const [createdEvents, setCreatedEvents] = useState([]);
  const [attendingEvents, setAttendingEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('created');

  useEffect(() => {
    fetchAllEvents();
  }, [ownerId]);

  const fetchAllEvents = async () => {
    try {
      const response = await axios.get(`${server}/event/user/${ownerId}/events`);
      setCreatedEvents(response.data.createdEvents);
      setAttendingEvents(response.data.attendingEvents);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch events');
      setLoading(false);
      console.error(err);
    }
  };

  const handleCancelRSVP = async (eventId) => {
    try {
      await axios.delete(`${server}/event/rsvp/${eventId}`, { withCredentials: true });
      const updatedAttendingEvents = attendingEvents.filter((event) => event._id !== eventId);
      setAttendingEvents(updatedAttendingEvents);
    } catch (error) {
      console.error('Failed to cancel RSVP:', error);
      alert('Failed to cancel RSVP: ' + (error.response?.data?.message || 'Error occurred'));
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="events-parent-container">
      <div className="events-parent-container-tabs-border">
        <nav className="events-parent-container-tabs-nav" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
              className={
                activeTab === tab.key
                  ? 'events-parent-container-tabs-button-active'
                  : 'events-parent-container-tabs-button'
              }
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      <div className="events-parent-container-tab-content">
        {activeTab === 'created' && (
          <div className="events-parent-created-events-section">
            {createdEvents.length > 0 ? (
              <MyEventsByOwner events={createdEvents} />
            ) : (
              <p>No created events found.</p>
            )}
          </div>
        )}
        {activeTab === 'attending' && (
          <div className="events-parent-attending-events-section">
            {attendingEvents.length > 0 ? (
              <MyEventsProps events={attendingEvents} onCancelRSVP={handleCancelRSVP} />
            ) : (
              <p>No attending events found.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EventsParentContainer;

