import React from 'react';
import { useSelector } from 'react-redux';


const Avatar = () => {
  const userProfile = useSelector((state) => state.user.user);
  const sellerProfiles = useSelector((state) => state.seller.sellers);
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const userSubscriptions = useSelector(state => state.user.subscriptions || []);
  const sellerSubscriptions = useSelector(state => state.seller.subscriptions || []);

  // Determine the correct avatar URL and profile type
  const profile = currentProfile === 'User' ? userProfile : sellerProfiles.find(seller => seller.name === currentProfile);
  const avatarUrl = profile?.avatar?.url;

  const getSubscriptionImageUrl = () => {
    let maxTier = 0;
  
    if (currentProfile === 'User' && userSubscriptions.length) {
      maxTier = userSubscriptions.reduce((max, sub) => {
        return sub.tier === "Super OG" ? 3 : (sub.tier === "OG" ? 2 : max);
      }, 0);
    } else if (sellerSubscriptions.length) {
      maxTier = sellerSubscriptions.reduce((max, sub) => {
        return sub.tier === "Super OG Shop" ? 3 : (sub.tier === "OG Shop" ? 2 : max);
      }, 0);
    }
  
    switch (maxTier) {
      case 3:
        return 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1715090157/super_og_youavf.svg';
      case 2:
        return 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1715090152/og_ircw65.svg';
      default:
        return null;
    }
  };
  
  const subscriptionIconUrl = getSubscriptionImageUrl();

  return (
    <div className="avatar-component-relative avatar-component-inline-block avatar-component-text-left">
      <div>
        <img
          src={avatarUrl || 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/1f973de0-c155-4748-9633-76fb5750d800/public'} 
          alt={`${currentProfile} Avatar`}
          className="avatar-component-avatar"
        />
        {subscriptionIconUrl && (
          <div className="avatar-component-subscription-icon">
            <img src={subscriptionIconUrl} alt="Subscription Status" className="avatar-component-subscription-img"/>
          </div>
        )}
      </div>
    </div>
  );
};

export default Avatar;

