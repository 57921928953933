import React, { useState, useEffect } from 'react';
import ProductCard from '../Route/ProductCard/ProductCard';

const MarketplaceProductCarousel = ({ data }) => {
  // Ensure data is always defined as an array
  const productData = Array.isArray(data) ? data : [];

  // Determine the number of products per slide based on screen width
  const [productsPerSlide, setProductsPerSlide] = useState(getProductsPerSlide());

  // Update productsPerSlide on window resize
  useEffect(() => {
    const handleResize = () => {
      setProductsPerSlide(getProductsPerSlide());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getProductsPerSlide() {
    if (window.innerWidth >= 1024) return 4;
    if (window.innerWidth >= 768) return 2;
    return 1;
  }

  // Group products into slides
  const slides = [];
  for (let i = 0; i < productData.length; i += productsPerSlide) {
    const slideItems = productData.slice(i, i + productsPerSlide);
    // Only include slides that are complete
    if (slideItems.length === productsPerSlide) {
      slides.push(slideItems);
    }
  }

  const [currentSlide, setCurrentSlide] = useState(0);

  const noSlides = slides.length === 0;

  const goToPrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  // Now, in the return statement, we can conditionally render based on noSlides
  return noSlides ? (
    <h1 className="marketplace-product-carousel-no-products-text">
      No retail products found!
    </h1>
  ) : (
    <div className="marketplace-product-carousel-container">
      <div className="marketplace-product-carousel-wrapper">
        {slides.length > 1 && (
          <button
            className="marketplace-product-carousel-nav-button prev"
            onClick={goToPrevSlide}
          >
            &#10094;
          </button>
        )}
        <div className="marketplace-product-carousel-slide">
          {slides[currentSlide] &&
            slides[currentSlide].map((product, idx) => (
              <ProductCard data={product} key={idx} />
            ))}
        </div>
        {slides.length > 1 && (
          <button
            className="marketplace-product-carousel-nav-button next"
            onClick={goToNextSlide}
          >
            &#10095;
          </button>
        )}
      </div>
      {slides.length > 1 && (
        <div className="marketplace-product-carousel-dots">
          {slides.map((_, idx) => (
            <span
              key={idx}
              className={`marketplace-product-carousel-dot ${
                currentSlide === idx ? 'active' : ''
              }`}
              onClick={() => setCurrentSlide(idx)}
            ></span>
          ))}
        </div>
      )}
    </div>
  );
};

export default MarketplaceProductCarousel;

