import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import Sidebar from '../components/Layout/Sidebar';
import CategorySection from "../components/Shop/CategorySection";
import FeaturedShop from "../components/Shop/FeaturedShop";
import SplitImagePromo from "../components/Shop/SplitImagePromo";
import ThreeTileGrid from "../components/Shop/ThreeTileGrid";
import ShopBySeller from "../components/Shop/ShopBySeller";
import HeroSliderMarketplace from "../components/Route/Hero/HeroSliderMarketplace";
import RetailCategorySection from "../components/Marketplace/RetailCategorySection";
import TrendingProductsCarosel from "../components/Products/TrendingProductsCarosel";
import ShopByBrand from "../components/Brands/ShopByBrandCarosel";
import Carousel4Slides from "../components/Carousel4Slides";
import SponsoredProducts from "../components/Sponsored/SponsoredProducts";
import SponsoredBrands from "../components/Sponsored/SponsoredBrands";
import SponsoredShops from "../components/Sponsored/SponsoredShops";
import ShopBySellerCircle from "../components/Shop/ShopBySellerCircle";
import DualProductAdCarouselProps from "../components/Sponsored/DualProductAdCarouselProps";
import QuadProductAdCarouselProps from "../components/Sponsored/QuadProductCarouselProps";
import SponsoredProductCarouselProps from "../components/Sponsored/SponsoredProductCarouselProps";
import SponsoredBrandCarouselProps from "../components/Sponsored/SponsoredBrandCarouselProps";
import SponsoredShopsCarouselProps from "../components/Sponsored/SponsoredShopsCarouselProps";
import TopBannerAdProps from "../components/Sponsored/TopBannerAdProps";
import BannerAdProps from "../components/Sponsored/BannerAdProps";
import MixedSponsoredAdsProps from "../components/Sponsored/MixedSponsoredAdsProps";
import CustomAdCarousel from "../components/Sponsored/CustomAdCarousel";
import TopBannerAdPropsMobile from "../components/Sponsored/TopBannerAdPropMobile";
import BannerAdPropsMobile from "../components/Sponsored/BannerAdPropsMobile";
import useWindowSize from "../hooks/useWindowSize";
import SponsoredProductCarouselPropsMobile from "../components/Sponsored/SponsoredProductsCarouselPropsMobile";
import SponsoredBrandCarouselPropsMobile from "../components/Sponsored/SponsoredBrandCarouselPropsMobile";
import SponsoredShopsCarouselPropsMobile from "../components/Sponsored/SponsoredShopsCarouselPropsMobile";
import MarketplaceProductCarousel from "../components/Marketplace/MarketplaceProductCarousel";
import ProductInfiniteMosaic from "../components/Marketplace/ProductInfiniteMosaic";
import axios from "axios";
import { adsServer } from "../server";

const MarketplacePage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const [productAds, setProductAds] = useState([]);
  const [shopAds, setShopAds] = useState([]);
  const [brandAds, setBrandAds] = useState([]);
  const [customAds, setCustomAds] = useState([]);
  const [buzzVibeProducts, setBuzzVibeProducts] = useState([]);
  const [shopImages, setShopImages] = useState([]); // New state for shop images
  const windowSize = useWindowSize();

  // Fetch sponsored ads (products, shops, and brands)
  useEffect(() => {
    const fetchSponsoredAds = async () => {
      try {
        const response = await axios.get(`${adsServer}/sponsored/sponsored-sidebar-ads-test`, {
          withCredentials: true,
          headers: {
            'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
          }
        });
        const allAds = response.data;

        // Filter for product ads, shop ads, and brand ads
        const productAds = allAds.filter((ad) => ad.type === 'product');
        const shopAds = allAds.filter((ad) => ad.type === 'shop');
        const brandAds = allAds.filter((ad) => ad.type === 'brand');
        const customAds = allAds.filter((ad) => ad.type === 'custom');

        setProductAds(productAds);
        setShopAds(shopAds);
        setBrandAds(brandAds);
        setCustomAds(customAds);
      } catch (error) {
        console.error('Error fetching sponsored ads:', error);
      }
    };

    fetchSponsoredAds();
  }, []);

  useEffect(() => {
    let filteredProducts = allProducts || [];

    // Filter for retail products
    filteredProducts = filteredProducts.filter((product) =>
      product.productType.includes('retail')
    );

    // Further filter by category if necessary
    if (categoryData) {
      filteredProducts = filteredProducts.filter(
        (product) => product.category === categoryData
      );
    }

    // Filter products from BuzzVibe Boutique using shop.handle
    const buzzVibeProducts = filteredProducts.filter(
      (product) =>
        product.shop &&
        product.shop.handle &&
        product.shop.handle.trim().toLowerCase() === 'buzzvibeboutique'
    );

    // Define shopImages manually or fetch from shop data
    // For demonstration, we'll define a static array
    const shopImages = [
      {
        url: 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/385d8f3f-e649-4b34-e3b4-cadceaa19900/public',
        alt: 'BuzzVibe Boutique - Image 1',
      },
      {
        url: 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/6a508243-40c8-4cbb-eaf8-042ddc025d00/public',
        alt: 'BuzzVibe Boutique - Image 2',
      },
      {
        url: 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/74bb0517-36a1-48ad-2eab-1b1d6a8bc600/public',
        alt: 'BuzzVibe Boutique - Image 3',
      },
      // Add more images as needed
    ];

    setData(filteredProducts);
    setBuzzVibeProducts(buzzVibeProducts);
    setShopImages(shopImages);
  }, [allProducts, categoryData]);

  // Select a specific product ad for TopBannerAdProps and BannerAdProps
  const topBannerAd = productAds.length > 0 ? productAds[0] : null;
  const bannerAd = productAds.length > 1 ? productAds[1] : null;

  const isMobile = windowSize.width <= 768;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={3} />
          <Sidebar />
          <div className="marketplace-page-content">
            {topBannerAd &&
              (isMobile ? (
                <TopBannerAdPropsMobile ad={topBannerAd} />
              ) : (
                <TopBannerAdProps ad={topBannerAd} />
              ))}
            <HeroSliderMarketplace />
            <ShopBySellerCircle />

            {/* Grid container with left and right sides */}
            <div className="marketplace-page-grid-container">
              {/* Left container (75%) */}
              <div className="marketplace-page-left-container">
                <RetailCategorySection />
                
                <DualProductAdCarouselProps ads={productAds} />
                <ThreeTileGrid products={buzzVibeProducts} shopImages={shopImages} />
                

                {isMobile ? (
                  <SponsoredProductCarouselPropsMobile ads={productAds} />
                ) : (
                  <SponsoredProductCarouselProps ads={productAds} />
                )}

                <Carousel4Slides />

                <QuadProductAdCarouselProps ads={productAds} />

                {isMobile ? (
                  <SponsoredBrandCarouselPropsMobile ads={brandAds} />
                ) : (
                  <SponsoredBrandCarouselProps ads={brandAds} />
                )}

                {isMobile ? (
                  <SponsoredShopsCarouselPropsMobile ads={shopAds} />
                ) : (
                  <SponsoredShopsCarouselProps ads={shopAds} />
                )}
                <FeaturedShop />
                <br />
                <br />
                <MarketplaceProductCarousel data={data} />
                <ProductInfiniteMosaic allProducts={data} />

                <SplitImagePromo />
                {bannerAd &&
                  (isMobile ? (
                    <BannerAdPropsMobile ad={bannerAd} />
                  ) : (
                    <BannerAdProps ad={bannerAd} />
                  ))}
              </div>

              {/* Right container (25%) */}
              <div className="marketplace-page-right-container">
                <MixedSponsoredAdsProps ads={[...productAds, ...brandAds, ...shopAds]} />
              </div>
            </div>

            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default MarketplacePage;


