import React from 'react';
import RecommendedConnections from '../Followers/RecommendedFriends';
import ShopBySellerCircle from '../Shop/ShopBySellerCircle';
import TrendingPostWidget from '../../widgets/TrendingPostWidget';

const CommunityDropdown = () => {

  return (
    <div className="community-dropdown-container">
      <div className="community-dropdown-inner">
        <h3 className="community-dropdown-title">Community</h3>
        <ShopBySellerCircle />
        
        <RecommendedConnections />
      </div>
    </div>
  );
};

export default CommunityDropdown;
