import React, { useState, Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FixedSizeList as List } from 'react-window';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { Popover, Transition } from '@headlessui/react';
import { postComment, fetchComments, deleteComment, editComment } from '../../redux/actions/post';
import Avatar from '../Layout/Avatar';
import axios from 'axios'; // Import axios for API requests
import { server } from '../../server'; // Adjust the import path as needed

const Comments = ({
  postId,
  totalComments,
  setTotalComments,
  comments = [],
  loggedInUserId,
  loggedInSellerId,
  commentProfileType,
  currentProfileId,
}) => {
  const dispatch = useDispatch();
  const [commentText, setCommentText] = useState('');
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [newCommentText, setNewCommentText] = useState('');
  const [currentCommentPage, setCommentCurrentPage] = useState(2);
  const [hasMoreComments, setHasMoreComments] = useState(comments.length < totalComments);
  const [newComments, setNewComments] = useState([]);
  const [likedComments, setLikedComments] = useState({});
  const { user, seller } = useSelector((state) => ({ user: state.user, seller: state.seller }));
  


  useEffect(() => {
    console.log("Initializing likedComments state...");
    console.log("Fetched comments:", comments);
  
    if (comments.length > 0) {
      const initialLikedComments = {};
  
      comments.forEach((comment, index) => {
        console.log(`Processing comment #${index + 1}:`, comment);
        
        if (comment._id && comment.isLikedByCurrentUser) {
          initialLikedComments[comment._id] = true;
          console.log(`Comment ${comment._id} is liked by the current user.`);
        } else {
          initialLikedComments[comment._id] = false;
          console.log(`Comment ${comment._id} is NOT liked by the current user.`);
        }
      });
  
      console.log("Initial likedComments state:", initialLikedComments);
      setLikedComments(initialLikedComments);
    } else {
      console.log("No comments found to initialize likedComments state.");
    }
  }, [comments]);
  
  
  
  // Like/unlike comment function
  const likeComment = async (commentId, likedBy, profileType, action) => {
    console.log(`Sending request to ${action} comment: commentId=${commentId}, likedBy=${likedBy}, profileType=${profileType}`);
    try {
      const response = await axios.patch(`${server}/like/comments/${commentId}/like?action=${action}`, {
        likedBy,
        profileType,
      });
      return response.data;
    } catch (error) {
      console.error(`Error ${action} comment:`, error);
      throw error;
    }
  };

  const handleLikeComment = async (commentId) => {
    const likedBy = currentProfileId; // Current logged-in user or shop ID
    const profileType = commentProfileType; // Either 'User' or 'Shop'
    const isAlreadyLiked = likedComments[commentId] || false; // Check if the comment is already liked

    const action = isAlreadyLiked ? 'unlike' : 'like'; // Determine action

    // Optimistically update UI
    setLikedComments((prev) => ({
      ...prev,
      [commentId]: !isAlreadyLiked,
    }));

    try {
      await likeComment(commentId, likedBy, profileType, action);
    } catch (error) {
      // Revert UI change in case of an error
      setLikedComments((prev) => ({
        ...prev,
        [commentId]: isAlreadyLiked,
      }));
      toast.error(`Failed to ${action} comment`);
    }
  };

  const loadMoreComments = useCallback(() => {
    if (!hasMoreComments) return;

    const nextPage = currentCommentPage;

    dispatch(fetchComments(postId, nextPage))
      .then((response) => {
        if (!response || !response.data || !response.data.comments) {
          throw new Error('Invalid response from server');
        }

        const moreComments = response.data.comments;

        if (moreComments.length === 0) {
          setHasMoreComments(false);
        } else {
          setNewComments((prev) => [...prev, ...moreComments]);
          setCommentCurrentPage((prev) => prev + 1);
        }
      })
      .catch((error) => {
        console.error('Error loading more comments:', error);
        toast.error('Failed to load more comments');
      });
  }, [currentCommentPage, dispatch, postId, hasMoreComments]);

  const handleCommentSubmit = (e) => {
    e.preventDefault();

    if (!commentText.trim()) {
      toast.error('Comment cannot be empty');
      return;
    }

    const newComment = {
        _id: Math.random().toString(36).substr(2, 9),
        commentText: commentText,
        profileType: commentProfileType,
        userId: commentProfileType === 'User' ? {
            ...user.user, // Access the correct nested object
            avatar: user.user?.avatar,
            name: user.user?.name
          } : null,
        shopId: commentProfileType === 'Shop' ? {
          ...seller,
          avatar: seller?.avatar,
          name: seller?.name
        } : null,
        createdAt: new Date().toISOString(),
        likeCount: 0,
        isLikedByCurrentUser: false,
      };

      console.log('New comment object:', newComment);

      if (commentProfileType === 'User') {
        console.log('User Info:', user);
      } else if (commentProfileType === 'Shop') {
        console.log('Seller Info:', seller);
      }

    setNewComments((prev) => [...prev, newComment]);
    setCommentText('');

    dispatch(postComment(postId, commentText, commentProfileType))
      .then((serverComment) => {
        const updatedComment = {
          ...newComment,
          ...serverComment,
        };
        console.log('Updated comment after server response:', updatedComment);

        setNewComments((prev) =>
          prev.map((comment) =>
            comment._id === newComment._id ? updatedComment : comment
          )
        );
        setTotalComments((prevCount) => prevCount + 1);
      })
      .catch(() => {
        setNewComments((prev) =>
          prev.filter((comment) => comment._id !== newComment._id)
        );
        toast.error('Failed to post comment');
      });
  };

  const handleDeleteComment = (commentId) => {
    dispatch(deleteComment(postId, commentId))
      .catch((error) => {
        toast.error('Failed to delete comment');
      });
  };

  const handleEditComment = (commentId, newText) => {
    dispatch(editComment(postId, commentId, newText))
      .catch((error) => {
        toast.error('Failed to edit comment');
      });
    setEditingCommentId(null);
    setNewCommentText('');
  };

  return (
    <>
      <List
        height={Math.min((comments.length + newComments.length) * 80, 740)}
        itemCount={comments.length + newComments.length}
        itemSize={70}
        width="100%"
        className="post-widget-comments-list"
      >
        {({ index, style }) => {
          const comment = index < comments.length ? comments[index] : newComments[index - comments.length];

          return (
            <div key={comment?._id} style={style} className="post-widget-comment-container">
              <div className="post-widget-comment-avatar-container">
                <img
                  className="post-widget-comment-avatar"
                  src={comment?.profileType === 'Shop' ? comment?.shopId?.avatar?.url : comment?.userId?.avatar?.url}
                  alt={comment?.profileType === 'Shop' ? comment?.shopId?.name || 'Shop Name' : comment?.userId?.name || 'User Name'}
                />
              </div>
              <div className="post-widget-comment-content">
                <span className="post-widget-comment-author">
                {comment?.profileType === 'Shop' ? comment?.shopId?.name : comment?.userId?.name}
                </span>
                <div className="post-widget-comment-box">
                  <div className="post-widget-comment-text-box">
                    {editingCommentId === comment?._id ? (
                      <input
                        type="text"
                        className="post-widget-comment-edit-input"
                        value={newCommentText}
                        onChange={(e) => setNewCommentText(e.target.value)}
                      />
                    ) : (
                      <span className="post-widget-comment-text">{comment?.commentText}</span>
                    )}
                  </div>
                  <button onClick={() => handleLikeComment(comment?._id)} className="like-button">
                    {likedComments[comment?._id] ? (
                        <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724845531/icon-BuzzBolt-02_ernlpk.png"
                        alt="Liked"
                        className="post-widget-like-icon"
                        />
                    ) : (
                        <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724845557/icon-BuzzBolt-01_qhtx6n.png"
                        alt="Not Liked"
                        className="post-widget-like-icon"
                        />
                    )}
                    <span className="like-count">
                        {comment.likeCount || 0}
                    </span>
                    </button>

                </div>

                {((comment?.profileType === 'User' && comment.userId && loggedInUserId === comment.userId._id) ||
                  (comment?.profileType === 'Shop' && comment.shopId && loggedInSellerId === comment.shopId._id)) && (
                  <Popover className="post-widget-comment-popover">
                    <Popover.Button>
                      <EllipsisHorizontalIcon className="post-widget-comment-options-icon" aria-hidden="true" />
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="post-widget-transition-enter"
                      enterFrom="post-widget-transition-enter-from"
                      enterTo="post-widget-transition-enter-to"
                      leave="post-widget-transition-leave"
                      leaveFrom="post-widget-transition-leave-from"
                      leaveTo="post-widget-transition-leave-to"
                    >
                      <Popover.Panel className="post-widget-comment-options-panel">
                        <div className="post-widget-comment-options-container">
                          <button
                            onClick={() => {
                              setEditingCommentId(comment?._id);
                              setNewCommentText(comment?.commentText);
                            }}
                            className="post-widget-comment-option-button"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleDeleteComment(comment?._id)}
                            className="post-widget-comment-option-button"
                          >
                            Delete
                          </button>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                )}

                {editingCommentId === comment?._id && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditComment(editingCommentId, newCommentText);
                    }}
                    className="post-widget-comment-save-button"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          );
        }}
      </List>

      {hasMoreComments && (
        <button onClick={loadMoreComments} className="load-more-comments-button">
          Load More Comments
        </button>
      )}

      <div className="post-widget-comment-input-container">
        <div className="post-widget-comment-input-flex">
          <Avatar />
          <div className="post-widget-comment-input-flex-1">
            <form className="post-widget-comment-input-form" onSubmit={handleCommentSubmit}>
              <textarea
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                rows={3}
                className="post-widget-comment-input"
                placeholder="Add your comment..."
              />
              <div className="post-widget-comment-submit-container">
                <button type="submit" className="post-widget-comment-submit-button">
                  POST
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comments;


