import React from 'react';

const categories = [
  {
    name: "New Arrivals",
    href: "/new-arrivals", // Updated to link to new arrivals route
    imageSrc: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393239/couture-category-buzzvibe_qimgdb.jpg",
  },
  {
    name: "BuzzVibe Favorites",
    href: "/buzzvibe-favorites", // If you have a special route for favorites
    imageSrc: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393233/art-category-buzzvibe_cfepbq.jpg",
  },
  {
    name: "Top Rated",
    href: "/top-rated", // Updated to link to top rated route
    imageSrc: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1702599501/b5_lpnl4j.jpg",
  },
  {
    name: "Trending",
    href: "/trending", // Updated to link to trending products route
    imageSrc: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393249/vintage-fashion-category-buzzvibe_l77p4f.jpg",
  },
  {
    name: "Sale",
    href: "/sale", // Updated if you have a special sale route
    imageSrc: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393583/collectibles-category-buzzvibe_xeeuv2.jpg",
  },
];

export default function RetailCategorySection() {
  return (
    <div className="category-marketplace-section-component-bg-black">
      <div className="category-marketplace-section-component-section-wrapper">
        <div className="category-marketplace-section-component-header-wrapper">
          <h2 className="category-marketplace-section-component-title">Shop by Category</h2>
        </div>

        <div className="category-marketplace-section-component-categories-wrapper">
          <div className="category-marketplace-section-component-categories-inner-wrapper">
            <div className="category-marketplace-section-component-categories-list">
              <div className="category-marketplace-section-component-categories-list-inner">
                {categories.map((category) => (
                  <a
                    key={category.name}
                    href={category.href}
                    className="category-marketplace-section-component-category-item"
                  >
                    <span aria-hidden="true" className="category-marketplace-section-component-category-image-wrapper">
                      <img src={category.imageSrc} alt="" className="category-marketplace-section-component-category-image" />
                    </span>
                    <span
                      aria-hidden="true"
                      className="category-marketplace-section-component-category-overlay"
                    />
                    <span className="category-marketplace-section-component-category-name">{category.name}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="category-marketplace-section-component-browse-link-wrapper">
          <a href="#" className="category-marketplace-section-component-browse-link-sm">
            Browse all categories
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
  )
}
