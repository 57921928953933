import React, { useState, useEffect } from "react";
import axios from "axios";
import { server } from "../../server";

const MyShopImages = ({ shopId }) => {
  const [images, setImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchImages = async () => {
      if (!shopId) {
        console.error("shopId is undefined");
        return;
      }
      try {
        const response = await axios.get(`${server}/image/shop/${shopId}/images`);
        if (response.data.success) {
          setImages(response.data.images);
        } else {
          console.error("Failed to fetch shop images");
        }
      } catch (error) {
        console.error("Error fetching shop images:", error);
      }
    };
  
    fetchImages();
  }, [shopId]);
  

  const openModal = (index = 0) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const previousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToImage = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <div className="my-images-component">
      <div className="my-images-header">
        <h3>Images</h3>
        <button className="see-all-button" onClick={() => openModal()}>
          See All
        </button>
      </div>

      <div className="my-images-component-image-grid">
        {images.slice(0, 4).map((image, index) => (
          <img
            key={image._id}
            src={image.url}
            alt={`Shop uploaded ${index + 1}`}
            onClick={() => openModal(index)}
            className="my-images-component-thumbnail"
          />
        ))}
      </div>

      {showModal && (
        <div className="my-images-component-modal-overlay" onClick={closeModal}>
          <div
            className="my-images-component-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="my-images-component-close-button" onClick={closeModal}>
              &times;
            </button>
            <div className="my-images-component-gallery">
              <button
                className="my-images-component-nav-button my-images-component-nav-button-left"
                onClick={previousImage}
              >
                &#10094;
              </button>
              <img
                src={images[currentImageIndex].url}
                alt={`Gallery ${currentImageIndex + 1}`}
                className="my-images-component-gallery-image"
              />
              <button
                className="my-images-component-nav-button my-images-component-nav-button-right"
                onClick={nextImage}
              >
                &#10095;
              </button>
            </div>

            <div className="my-images-component-thumbnail-navigation">
              {images.map((image, index) => (
                <img
                  key={image._id}
                  src={image.url}
                  alt={`Thumbnail ${index + 1}`}
                  className={`my-images-component-thumbnail-small ${
                    currentImageIndex === index ? "active" : ""
                  }`}
                  onClick={() => goToImage(index)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyShopImages;

