import React from "react";
import { FaCheck } from "react-icons/fa";


const OnboardingSteps = ({ currentStep }) => {
  const steps = [
    { id: 1, label: "Create Shop" },
    { id: 2, label: "Verify Email" },
    { id: 3, label: "Complete Onboarding" },
    { id: 4, label: "Payment Method Setup" },
    { id: 5, label: "Choose Subscription" },
  ];

  return (
    <div className="onboarding-steps">
      {steps.map((step, index) => (
        <div key={step.id} className="onboarding-step">
          <div
            className={`onboarding-step-circle ${
              currentStep > step.id
                ? "completed"
                : currentStep === step.id
                ? "current"
                : "upcoming"
            }`}
          >
            {currentStep > step.id ? (
              <FaCheck className="onboarding-step-icon" />
            ) : (
              step.id
            )}
          </div>
          <div
            className={`onboarding-step-label ${
              currentStep > step.id
                ? "completed"
                : currentStep === step.id
                ? "current"
                : "upcoming"
            }`}
          >
            {step.label}
          </div>
          {index < steps.length - 1 && (
            <div
              className={`onboarding-step-line ${
                currentStep > step.id ? "completed-line" : "upcoming-line"
              }`}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default OnboardingSteps;

