import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SponsorProductCampaign from '../../components/Sponsored/SponsorProductCampaign';
import SponsorShopCampaign from '../../components/Sponsored/SponsorShopCampaign';
import SponsorBrandCampaign from '../../components/Sponsored/SponsorBrandCampaign';
import CreateCustomAdCampaign from '../../components/Sponsored/CreateCustomAdCampaign';
import CreateAdSteps from '../../components/Sponsored/CreateAdSteps';
import CampaignReview from '../../components/Sponsored/CampaignReview';
import CampaignTotals from '../../components/Sponsored/CampaignTotals';
import { adsServer } from '../../server';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { FiPackage, FiShoppingCart, FiTag, FiSettings, FiPlus } from 'react-icons/fi';
import Loader from '../Layout/Loader';

const CampaignCreator = () => {
    const [isCampaignStarted, setIsCampaignStarted] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [sponsoredAds, setSponsoredAds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAdSetModalOpen, setIsAdSetModalOpen] = useState(false);
    const [campaignAds, setCampaignAds] = useState({});
    const [adSet, setAdSet] = useState({});
    const [openAdComponents, setOpenAdComponents] = useState({});
    const [products, setProducts] = useState([]);
    const { seller } = useSelector((state) => state.seller);
    const [savedAdConfigurations, setSavedAdConfigurations] = useState({});
    const [editingAd, setEditingAd] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const adOptions = [
        { label: "Product", component: <SponsorProductCampaign />, type: "product", icon: <FiPackage /> },
        { label: "Shop", component: <SponsorShopCampaign />, type: "shop", icon: <FiShoppingCart /> },
        { label: "Brand", component: <SponsorBrandCampaign />, type: "brand",  icon: <FiTag /> },
        { label: "Custom", component: <CreateCustomAdCampaign />, type: "custom",  icon: <FiSettings /> },
    ];

    useEffect(() => {
        const fetchSponsoredAds = async () => {
            setIsLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${adsServer}/sponsored/sponsored-ads`, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                    },
                });
                const sortedAds = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setSponsoredAds(sortedAds);
            } catch (error) {
                console.error('Error fetching sponsored ads:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSponsoredAds();
    }, []);

    useEffect(() => {
        if (selectedOptions.includes("Sponsor Product")) {
            const fetchProducts = async () => {
                try {
                    const { data } = await axios.get(`${adsServer}/product/get-all-products-shop/${seller._id}`, {
                        withCredentials: true,
                    });
                    setProducts(data.products);
                } catch (error) {
                    console.error('Error fetching products:', error);
                }
            };

            fetchProducts();
        }
    }, [selectedOptions]);

    const toggleOption = (option) => {
        setSelectedOptions((prev) =>
            prev.includes(option)
                ? prev.filter((item) => item !== option)
                : [...prev, option]
        );
    };

    const proceedToNextStep = () => {
        if (activeStep === 1 && selectedOptions.length === 0) {
            alert("Please select at least one ad type.");
            return;
        }
        if (activeStep === 2 && Object.keys(savedAdConfigurations).length === 0) {
            alert("Please save at least one ad configuration before proceeding.");
            return;
        }
        setActiveStep((prevStep) => Math.min(prevStep + 1, 5));

        // Initialize open ad components for step 2
        if (activeStep === 1) {
            const initialOpenComponents = {};
            selectedOptions.forEach(option => {
                const adType = adOptions.find(ad => ad.label === option).type;
                initialOpenComponents[adType] = 1; // One component open by default
            });
            setOpenAdComponents(initialOpenComponents);
            if (selectedOptions.length > 0) {
                const firstSelectedOption = adOptions.find(ad => ad.label === selectedOptions[0]).type;
                setSelectedComponent(firstSelectedOption);
            }
        }
    };

    const goToPreviousStep = () => {
        setActiveStep((prevStep) => Math.max(prevStep - 1, 1)); 
    };

    const handleStartCampaign = () => {
        setIsCampaignStarted(true);
        setActiveStep(1); 
    };

    const handleAdSetSave = (newAdSet, details, adType) => {
        setCampaignAds((prev) => {
            const existingAds = prev[adType] || [];
            const adDetails = {
                type: adType,
                details,
                adSet: {
                    images: newAdSet.images,
                    files: newAdSet.files,
                    positions: newAdSet.positions,
                    scales: newAdSet.scales,
                },
            };
    
            if (editingAd && editingAd.adType === adType) {
                // Update the existing ad
                const updatedAds = [...existingAds];
                updatedAds[editingAd.index] = adDetails;
                return {
                    ...prev,
                    [adType]: updatedAds,
                };
            } else {
                // Add a new ad
                return {
                    ...prev,
                    [adType]: [...existingAds, adDetails],
                };
            }
        });
    
        setSavedAdConfigurations((prev) => ({
            ...prev,
            [adType]: [...(prev[adType] || []), { adSet: newAdSet, details }],
        }));
        setOpenAdComponents((prev) => ({
            ...prev,
            [adType]: Math.max((prev[adType] || 1) - 1, 0),
        }));
    
        setIsAdSetModalOpen(false);
        setEditingAd(null); // Reset the editing state
        setIsEditMode(false);
    };
    

    const handleEditClick = (adType, index) => {
        const adToEdit = campaignAds[adType][index];
        
        setAdSet(adToEdit.adSet || {}); // Load the ad set into the state for editing
        
        // Pass the ad details when editing
        setEditingAd({
            adType,
            index,
            details: adToEdit.details || {}, // Pass details for pre-filling the form
        });
        setIsEditMode(true);
        
        // Expand the ad component in the UI
        setOpenAdComponents((prev) => ({
            ...prev,
            [adType]: 1, 
        }));
    };
    
    

    const handleSubmitCampaign = async () => {
        setIsLoading(true);
        try {
            const adsArray = Object.values(campaignAds).flat();
            console.log('Flattened Campaign Ads:', adsArray);  // Check if adsArray is correct
            
            const formData = new FormData();
        
            // Iterate through each ad and append relevant data to FormData
            adsArray.forEach((ad, index) => {
                console.log(`Processing ad ${index}:`, ad);  // Log each ad being processed
                
                // Log type and details of each ad
                console.log(`Ad type: ${ad.type}`);
                console.log(`Ad details:`, ad.details);
                console.log(`Ad files:`, ad.adSet.files);
                
                formData.append(`ads[${index}][type]`, ad.type);  
                formData.append(`ads[${index}][details]`, JSON.stringify(ad.details)); 
                
                // Iterate over adSet and append either files or URLs for each format
                Object.entries(ad.adSet.images).forEach(([format, imageOrFile]) => {
                    if (ad.adSet.files && ad.adSet.files[format] instanceof File) {
                        // Log the file being appended to FormData
                        console.log(`Appending file for ${format}:`, ad.adSet.files[format]);
                        formData.append(`ads[${index}][adSet][images][${format}]`, ad.adSet.files[format]);
                    } else if (typeof imageOrFile === 'string') {
                        // Log the URL being appended to FormData
                        console.log(`Appending URL for ${format}:`, imageOrFile);
                        formData.append(`ads[${index}][adSet][images][${format}]`, imageOrFile);
                    }
                });
    
                // Log the positions before appending
                console.log(`Ad positions for ${index}:`, ad.adSet.positions);
    
                // Serialize adSet positions (must be stringified JSON)
                if (ad.adSet && ad.adSet.positions) {
                    formData.append(`ads[${index}][adSet][positions]`, JSON.stringify(ad.adSet.positions));
                }
                if (ad.adSet && ad.adSet.scales) {
                    formData.append(`ads[${index}][adSet][scales]`, JSON.stringify(ad.adSet.scales)); 
                }
            });
    
            // Debugging: Log FormData entries
            console.log('FormData entries:');
            for (let pair of formData.entries()) {
                console.log(`${pair[0]}:`, pair[1]);
            }
    
            // Send FormData via POST request
            const response = await axios.post(`${adsServer}/sponsored/create-campaign`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                },
            });
        
            toast.success('Campaign created successfully!');
            // Reset campaign state
            setCampaignAds({});
            setSelectedOptions([]);
            setActiveStep(1);
            setIsCampaignStarted(false);
        } catch (error) {
            console.error('Error submitting campaign:', error);
            toast.error('Error creating campaign');
        } finally {
            setIsLoading(false);
        }
    };
    
    
    
    
    
    
    
    
    const handleAddAnother = (adType) => {
        setOpenAdComponents((prev) => ({
            ...prev,
            [adType]: (prev[adType] || 0) + 1,
        }));
    };
    const handleIconClick = (type) => {
        setSelectedComponent(type); 
    };
    

    const totalAds = Object.values(campaignAds).flat().length;
    const totalDuration = Object.values(campaignAds).flat().reduce((acc, ad) => acc + ad.details.adDuration, 0);
    const totalAdSpend = Object.values(campaignAds).flat().reduce((acc, ad) => acc + ad.details.adSpend, 0);

    if (isLoading) {
        return <Loader />; 
    }

    return (
        <div className="shop-create-advertisement-campaign-container">
            <div className="shop-create-advertisement-campaign-inner-container">
            
                            <div className="shop-create-advertisement-campaign-box-modal">
                                {!isCampaignStarted ? (
                                    <button
                                        className="create-ad-button create-ad-start-button"
                                        onClick={handleStartCampaign}
                                    >
                                        Start Campaign
                                    </button>
                                ) : (
                                    <div>
                                        <CreateAdSteps activeStep={activeStep} />
                                        <h2 className="shop-create-advertisement-campaign-title">
                                            {activeStep === 1 && "Choose Your Ad Type"}
                                            {activeStep === 2 && "Customize Your Ads"}
                                            {activeStep === 3 && "Review Your Ads"}
                                            {activeStep === 4 && "Checkout"}
                                        </h2>

                                        {activeStep === 1 && (
                                                <div className="shop-create-advertisement-campaign-options-row">
                                                    {adOptions.map((option, index) => (
                                                        <div key={index} className="shop-create-advertisement-campaign-option-item">
                                                            <div className="shop-create-advertisement-campaign-icon-label-container">
                                                                <div className="shop-create-advertisement-campaign-option-icon">
                                                                    
                                                                    {index === 0 && <FiPackage />}  
                                                                    {index === 1 && <FiShoppingCart />} 
                                                                    {index === 2 && <FiTag />}  
                                                                    {index === 3 && <FiSettings />} 
                                                                </div>
                                                                <span className="shop-create-advertisement-campaign-option-label">{option.label}</span>
                                                            </div>
                                                            <div className="switch large">
                                                            <input
                                                                className="switch-input"
                                                                id={`option-toggle-${index}`}
                                                                type="checkbox"
                                                                checked={selectedOptions.includes(option.label)}
                                                                onChange={() => toggleOption(option.label)}
                                                            />
                                                            <label className="switch-paddle" htmlFor={`option-toggle-${index}`}></label>
                                                        </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                        
{activeStep === 2 && (
    <div className="shop-create-advertisement-campaign-expanded-sections">
        <div className="selected-options-display">
            {selectedOptions.map((optionLabel, index) => {
                const option = adOptions.find((opt) => opt.label === optionLabel);
                return (
                    <div
                        key={index}
                        className={`shop-create-advertisement-campaign-option-item ${selectedComponent === option.type ? 'selected' : ''}`}
                        onClick={() => handleIconClick(option.type)}
                    >
                        <div className="shop-create-advertisement-campaign-icon-label-container">
                            <div className="shop-create-advertisement-campaign-option-icon">
                                {option.icon}
                            </div>
                            <span className="shop-create-advertisement-campaign-option-label">
                                {option.label}
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>

        {/* Only show the component for the selected option */}
        {adOptions.map((option) => {
    if (selectedComponent === option.type) {
        const savedConfigs = campaignAds[option.type] || [];
        return (
            <div key={option.label} className="ad-config-section">
                {/* Render any saved ad configurations */}
                {savedConfigs.length > 0 &&
                    savedConfigs.map((config, index) => {
                        // Determine the correct image and alt text based on ad type
                        let imageSrc = '';
                        let altText = '';

                        if (option.type === 'shop') {
                            imageSrc = config.details.shopImage;
                            altText = config.details.shopName;
                        } else if (option.type === 'product') {
                            imageSrc = config.details.productImage;
                            altText = config.details.productName;
                        } else if (option.type === 'brand') {
                            imageSrc = config.details.brandImage; // Assuming brandImage is part of config.details
                            altText = config.details.brandName;
                        } else if (option.type === 'custom') {
                            // For custom ads, use a custom image or fallback to an uploaded one
                            imageSrc = config.details.customImage || config.details.productImage; // Assuming customImage is part of config.details
                            altText = config.details.adText || 'Custom Ad';
                        } else if (config.adSet && config.adSet.images) {
                            const selectedFormat = config.adSet.selectedFormat || 'Top Banner Ad'; // Default to 'Top Banner Ad'
                            imageSrc = config.adSet.images[selectedFormat] || config.details.productImage; // Fallback to product image
                            altText = config.details.productName || 'Ad Image';
                        }

                        return (
                            editingAd?.adType !== option.type || editingAd?.index !== index ? (
                                <div key={`${option.label}-${index}`} className="ad-overview-row">
                                    <div className="ad-overview-content">
                                        <img
                                            src={imageSrc}
                                            alt={altText}
                                            className="ad-overview-image"
                                        />
                                        <div className="ad-overview-details">
                                            <h4>{`${option.label} Overview ${index + 1}`}</h4>
                                            <p><strong>Ad Spend:</strong> ${config.details.adSpend}</p>
                                            <p><strong>Duration:</strong> {config.details.adDuration} days</p>

                                            {/* Display different fields based on the ad type */}
                                            {option.type === 'shop' && (
                                                <p><strong>Shop:</strong> {config.details.shopName}</p>
                                            )}
                                            {option.type === 'product' && (
                                                <p><strong>Product:</strong> {config.details.productName}</p>
                                            )}
                                            {option.type === 'brand' && (
                                                <p><strong>Brand:</strong> {config.details.brandName}</p>
                                            )}
                                            {option.type === 'custom' && (
                                                <>
                                                    <p><strong>Ad Text:</strong> {config.details.adText}</p>
                                                    <p><strong>Link:</strong> {config.details.adLink}</p>
                                                </>
                                            )}

                                            {/* Render format-specific information for all types */}
                                            {config.adSet && (
                                                <>
                                                    <p><strong>Ad Format:</strong> {config.adSet.selectedFormat}</p>
                                                    <p><strong>Position:</strong> X: {config.adSet.positions?.[config.adSet.selectedFormat]?.x || 0}, Y: {config.adSet.positions?.[config.adSet.selectedFormat]?.y || 0}</p>
                                                    <p><strong>Scale:</strong> {config.adSet.scales?.[config.adSet.selectedFormat] || 1}</p>
                                                </>
                                            )}

                                            <button onClick={() => handleEditClick(option.type, index)}>
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        );
                    })}

                        {/* Render the new ad config for the selected component */}
                        {[...Array(openAdComponents[option.type] || 0)].map((_, idx) => {
                            return (
                                <div key={`${option.type}-${idx}`} className="new-ad-config">
                                    {React.cloneElement(option.component, {
                                        onSave: handleAdSetSave,
                                        adType: option.type,
                                        details: editingAd?.details || {},
                                    })}
                                </div>
                            );
                        })}

                        {/* Button to add another ad of this type */}
                        {savedConfigs.length > 0 && (
                            <button
                                className="add-new-ad-button"
                                onClick={() => handleAddAnother(option.type)}
                            >
                                <FiPlus style={{ marginRight: '8px' }} />
                                Add Another {option.label}
                            </button>
                        )}
                    </div>
                );
            }
            return null;
        })}
    </div>
)}


{activeStep === 3 && (
    <div className="shop-create-advertisement-review-section">
        <CampaignReview 
            campaignAds={campaignAds} 
            onEdit={(adType, index) => {
                setActiveStep(2); 
                setSelectedComponent(adType); 
                handleEditClick(adType, index); 
            }}
        />
    </div>
)}


                                        {activeStep === 4 && (
                                            <div className="shop-create-advertisement-checkout-section">
                                                <h3>Checkout</h3>
                                                <div className="shop-create-advertisement-checkout-summary">
                                                    <p><strong>Total Ads:</strong> {totalAds}</p>
                                                    <p><strong>Total Duration:</strong> {totalDuration} days</p>
                                                    <p><strong>Total Ad Spend:</strong> ${totalAdSpend}</p>
                                                </div>
                                                <button
                                                    className="create-ad-button create-ad-complete-button"
                                                    onClick={handleSubmitCampaign}
                                                >
                                                    Complete
                                                </button>
                                            </div>
                                        )}

                                        <div className="create-ad-button-group">
                                            {activeStep > 1 && (
                                                <button
                                                    className="create-ad-previous-button"
                                                    onClick={goToPreviousStep}
                                                >
                                                    Previous Step
                                                </button>
                                            )}
                                            {activeStep < 4 && (
                                            <button
                                                className={`create-ad-next-button ${activeStep === 2 && Object.keys(savedAdConfigurations).length === 0 ? 'disabled' : ''}`}
                                                onClick={proceedToNextStep}
                                                disabled={activeStep === 2 && Object.keys(savedAdConfigurations).length === 0}  
                                            >
                                                Next Step
                                            </button>
                                        )}

                                        </div>
                                    </div>
                                )}
                            </div>
                            
                                <CampaignTotals campaignAds={Object.values(campaignAds).flat()} />
                                </div>
                        </div>
                        
                  
    );
};

export default CampaignCreator;