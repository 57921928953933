import { categoriesData, productData } from "../../static/data";
import React, { useState, Fragment, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineShoppingCart,
  AiOutlineClose
} from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { BiMenuAltLeft } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import DropDown from "./DropDown";
import Navbar from "./Navbar";
import Cart from "../cart/Cart";
import Wishlist from "../Wishlist/Wishlist";
import { RxCross1 } from "react-icons/rx";
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AvatarDropdown from "./AvatarDropdown";
import { useSelector, useDispatch } from "react-redux";
import { toggleCart, toggleWishlist, toggleAvatarDropdown,  toggleTopLevelDropdown } from "../../state/uiSlice";
import SearchBox from "./SearchBox";
import CategoryGrid from "./CategoriesGrid";
import TopLevelDropdown from "./TopLevelDropdown";
import SearchBar from "./SearchBar";

const Header = ({ activeHeading }) => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);
  const { wishlist } = useSelector((state) => state.wishlist);
  const profileType = sessionStorage.getItem('currentProfile') || 'User'; 
  const cartState = useSelector(state => state.cart.carts); 
  const cart = cartState[profileType] || [];
  const { allProducts } = useSelector((state) => state.products);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const searchBoxRef = useRef(null);
  const [active, setActive] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const dropdownRef = useRef(null);
  const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [open, setOpen] = useState(false);
  const isCartOpen = useSelector((state) => state.ui.isCartOpen);
  const isWishlistOpen = useSelector((state) => state.ui.isWishlistOpen);
  const isAvatarDropdownOpen = useSelector((state) => state.ui.isAvatarDropdownOpen);
  const isTopLevelDropdownOpen = useSelector((state) => state.ui.isTopLevelDropdownOpen);
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const handleOpenWishlist = () => {
    dispatch(toggleWishlist());
  };

  const handleOpenCart = () => {
    dispatch(toggleCart()); 
  };

  const handleToggleDropdown = () => {
    dispatch(toggleTopLevelDropdown());
  };

  const handleToggleAvatarDropdown = () => {
    dispatch(toggleAvatarDropdown());
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const clearSearch = () => {
    setSearchTerm("");
    setSearchData(null);
  };

  const handleFocus = () => {
    if (searchTerm) {
      setSearchData(
        allProducts.filter(product =>
          product.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  };

  const logoutHandler = () => {
    axios.post(`${server}/user/logout`, {}, { 
      withCredentials: true 
    })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload(true);
        navigate("/login");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  return (
    <>
      <div className="main-header-component-container">
        <div className="main-header-component-wrapper">
          <div>
            <Link to="/">
              <img
                src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724797193/BuzzVibe-TM-Gradient-outline_jfxvg6.png"
                className="main-header-component-site-logo"
                alt=""
              />
            </Link>
          </div>
          
          <div onClick={handleToggleDropdown} ref={dropdownRef}>
            <div className="main-header-component-categories">
              <BiMenuAltLeft size={30} className="main-header-component-menu-icon" />
              <button className="main-header-component-categories-button">
                All Categories
              </button>
              <IoIosArrowDown
                size={20}
                className="main-header-component-arrow-icon"
                onClick={() => setDropDown(!dropDown)}
              />
              {isTopLevelDropdownOpen && (
                <TopLevelDropdown
                  categoriesData={categoriesData}
                  setDropDown={setDropDown}
                />
              )}
            </div>
          </div>
                <SearchBar />
          

          <div className="main-header-component-actions">
            <div className="main-header-component-action">
              <div
                className="main-header-component-wishlist"
                onClick={handleOpenWishlist}
              >
                <AiOutlineHeart size={30} color="rgb(255 255 255 / 83%)" />
                <span className="main-header-component-wishlist-count">
                  {wishlist && wishlist.length}
                </span>
              </div>
            </div>

            <div className="main-header-component-action">
              <div
                className="main-header-component-cart"
                onClick={handleOpenCart}
              >
                <AiOutlineShoppingCart size={30} color="rgb(255 255 255 / 83%)" />
                <span className="main-header-component-cart-count">
                  {cart && cart.length}
                </span>
              </div>
            </div>

            <div className="main-header-component-action">
              <div className="main-header-component-avatar" onClick={handleToggleAvatarDropdown}>
                {isAuthenticated ? (
                  <>
                    <img
                      src={user.avatar?.url || 'default-avatar-url'}
                      alt={`${currentProfile} Avatar`}
                      className="main-header-component-avatar-img"
                    />
                    {isAvatarDropdownOpen && <AvatarDropdown />}
                  </>
                ) : (
                  <Link to="/login">
                    <CgProfile size={30} color="rgb(255 255 255 / 83%)" />
                  </Link>
                )}
              </div>
            </div>

            {isCartOpen && <Cart />}
            {isWishlistOpen && <Wishlist />}
          </div>
        </div>
      </div>

      <div
        className={`main-header-component-mobile-header ${active ? "active" : ""}`}
      >
        <div className="main-header-component-mobile-header-inner">
          <div>
            <BiMenuAltLeft
              size={40}
              className="main-header-component-mobile-menu-icon"
              onClick={() => setOpen(true)}
            />
          </div>
          <div>
            <Link to="/">
              <img
                src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724797193/BuzzVibe-TM-Gradient-outline_jfxvg6.png"
                alt=""
                className="main-header-component-mobile-logo"
              />
            </Link>
          </div>
          <div>
            <div
              className="main-header-component-mobile-cart"
              onClick={handleOpenCart} 
            >
              <AiOutlineShoppingCart size={30} />
              <span className="main-header-component-mobile-cart-count">
                {cart && cart.length}
              </span>
            </div>
          </div>
          {isCartOpen && <Cart />}
          {isWishlistOpen && <Wishlist />}
        </div>

        {open && (
          <div className="main-header-component-sidebar">
            <div className="main-header-component-sidebar-inner">
              <div className="main-header-component-sidebar-top">
              <div
                className="main-header-component-wishlist-icon"
                onClick={() => {
                  dispatch(toggleWishlist());
                  setOpen(false); 
                }}
              >
                <AiOutlineHeart size={30} />
                <span className="main-header-component-wishlist-count">
                  {wishlist && wishlist.length}
                </span>
              </div>
                <RxCross1
                  size={30}
                  className="main-header-component-close-icon"
                  onClick={() => setOpen(false)}
                />
              </div>

              <div className="main-header-component-search-box">
              <SearchBar />
              </div>

              
              <TopLevelDropdown
                  categoriesData={categoriesData}
                  setDropDown={setDropDown}
                />
              
            </div>
          </div>
        )}
      </div>
    </>
  );
};


export default Header;
