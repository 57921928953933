import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addTocart } from '../../redux/actions/cart';
import { addToWishlist, removeFromWishlist } from '../../redux/actions/wishlist';
import { toast } from 'react-toastify';
import { AiFillHeart, AiOutlineHeart, AiOutlineShoppingCart } from 'react-icons/ai';

// Optional: Import any additional icons or components you need

// Helper function to concatenate class names
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const QuickViewModal = ({ product, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);

  const isFavorited = wishlist && wishlist.find((item) => item._id === product._id);

  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [quantity, setQuantity] = useState(1);

  // Determine if the product is a variable product
  const isVariableProduct = Array.isArray(product.variants) && product.variants.length > 0;

  // Extract unique colors and sizes if variable product
  const colors = isVariableProduct
    ? Array.from(new Set(product.variants.map((variant) => variant.color))).map((color) => ({
        name: color,
        bgColor: getColorBg(color),
      }))
    : [];

  const sizes = isVariableProduct
    ? Array.from(new Set(product.variants.map((variant) => variant.size))).map((size) => ({
        name: size,
        inStock: product.variants.some((variant) => variant.size === size && variant.inStock === true),
      }))
    : [];

  // Helper function to map color names to background colors
  function getColorBg(colorName) {
    const colorMap = {
      Black: '#000000',
      White: '#FFFFFF',
      Red: '#FF0000',
      Blue: '#0000FF',
      Green: '#008000',
      // Add more mappings as needed
    };
    return colorMap[colorName] || '#CCCCCC'; // Default color
  }

  // Set default variant on modal open
  useEffect(() => {
    if (isVariableProduct && product.variants.length > 0) {
      setSelectedColor(colors[0]);
      const defaultVariant = product.variants.find((variant) => variant.color === colors[0].name);
      if (defaultVariant) {
        setSelectedSize({ name: defaultVariant.size, inStock: defaultVariant.inStock });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  // Handle variant selection
  const handleColorChange = (color) => {
    setSelectedColor(color);
    // Reset size when color changes
    const matchingVariants = product.variants.filter((variant) => variant.color === color.name);
    if (matchingVariants.length > 0) {
      setSelectedSize({ name: matchingVariants[0].size, inStock: matchingVariants[0].inStock });
      setQuantity(1);
    }
  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    setQuantity(1);
  };

  // Handle quantity change
  const handleQuantityChange = (amount) => {
    setQuantity((prevQty) => {
      const maxQty = isVariableProduct
        ? product.variants.find(
            (variant) =>
              variant.color === (selectedColor ? selectedColor.name : '') &&
              variant.size === (selectedSize ? selectedSize.name : '')
          )?.stock || 1
        : product.stock || 1;
      const newQty = prevQty + amount;
      if (newQty < 1) return 1;
      if (newQty > maxQty) return maxQty;
      return newQty;
    });
  };

  // Handle Add to Wishlist
  const handleFavorite = () => {
    if (isFavorited) {
      dispatch(removeFromWishlist(product));
      toast.info('Removed from wishlist');
    } else {
      dispatch(addToWishlist(product));
      toast.success('Added to wishlist');
    }
  };

  // Handle Add to Cart
  const handleAddToCart = () => {
    if (isVariableProduct) {
      if (!selectedColor || !selectedSize) {
        toast.error('Please select color and size.');
        return;
      }

      // Find the matching variant
      const matchingVariant = product.variants.find(
        (variant) => variant.color === selectedColor.name && variant.size === selectedSize.name
      );

      if (!matchingVariant) {
        toast.error('Selected variant not found.');
        return;
      }

      if (!matchingVariant.inStock) {
        toast.error('Selected variant is out of stock.');
        return;
      }

      // Check if item already exists in cart
      const isItemExists =
        cart &&
        cart.find(
          (i) => i._id === product._id && i.variant && i.variant._id === matchingVariant._id
        );
      if (isItemExists) {
        toast.error('Item already in cart!');
        return;
      }

      // Prepare cart data
      const cartData = {
        ...product,
        qty: quantity, // Use selected quantity
        variant: matchingVariant,
        price: matchingVariant.price || product.originalPrice,
      };

      // Dispatch add to cart
      dispatch(addTocart(cartData));
      toast.success('Item added to cart successfully!');
      onClose();
    } else {
      // Simple product
      // Check if item already exists in cart
      const isItemExists = cart && cart.find((i) => i._id === product._id && !i.variant);
      if (isItemExists) {
        toast.error('Item already in cart!');
        return;
      }

      // Check stock
      if (product.stock < 1) {
        toast.error('Product is out of stock!');
        return;
      }

      // Prepare cart data
      const cartData = {
        ...product,
        qty: quantity, // Use selected quantity
        price: product.originalPrice || product.price,
      };

      // Dispatch add to cart
      dispatch(addTocart(cartData));
      toast.success('Item added to cart successfully!');
      onClose();
    }
  };

  // Handle View Full Details
  const handleViewDetails = () => {
    navigate(`/product/${product._id}`);
    onClose();
  };

  return (
    <div className="mosaic-modal-overlay" onClick={onClose} role="dialog" aria-modal="true">
      <div className="mosaic-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="mosaic-modal-close" onClick={onClose} aria-label="Close">
          ×
        </button>
        <div className="mosaic-modal-inner">
          <div className="mosaic-modal-image-section">
            {isVariableProduct && selectedColor ? (
              <img
                src={
                  product.variants.find(
                    (variant) =>
                      variant.color === selectedColor.name &&
                      variant.size === (selectedSize ? selectedSize.name : '')
                  )?.image?.url || '/placeholder-image.jpg'
                }
                alt={product.name}
                className="mosaic-modal-image"
              />
            ) : (
              <img
                src={product.images && product.images[0]?.url ? product.images[0].url : '/placeholder-image.jpg'}
                alt={product.name}
                className="mosaic-modal-image"
              />
            )}
          </div>
          <div className="mosaic-modal-details-section">
            <h2 className="mosaic-modal-product-name">{product.name}</h2>
            <p className="mosaic-modal-price">
              $
              {isVariableProduct && selectedColor
                ? (
                    product.variants.find(
                      (variant) =>
                        variant.color === selectedColor.name &&
                        variant.size === (selectedSize ? selectedSize.name : '')
                    )?.price?.toFixed(2) || product.originalPrice?.toFixed(2) || 'N/A'
                  )
                : product.originalPrice
                ? product.originalPrice.toFixed(2)
                : 'N/A'}
            </p>
            <div className="mosaic-modal-rating">
              <span className="mosaic-modal-rating-value">{product.ratings || '0'}</span>
              <div className="mosaic-modal-stars">
                {Array.from({ length: 5 }, (_, i) => (
                  <span
                    key={i}
                    className={classNames(
                      i < (product.ratings || 0) ? 'mosaic-modal-star-filled' : 'mosaic-modal-star-empty'
                    )}
                  >
                    ★
                  </span>
                ))}
              </div>
              <span className="mosaic-modal-review-count">
                ({product.reviewCount || 0} reviews)
              </span>
            </div>
            <p className="mosaic-modal-description">{product.description}</p>

            {/* Wishlist Icon */}
            <div
              className="mosaic-modal-wishlist-icon"
              onClick={handleFavorite}
              aria-label={isFavorited ? 'Remove from wishlist' : 'Add to wishlist'}
            >
              {isFavorited ? (
                <AiFillHeart size={25} color="red" />
              ) : (
                <AiOutlineHeart size={25} color="#333" />
              )}
            </div>

            {/* Color Picker */}
            {isVariableProduct && colors.length > 0 && (
              <div className="mosaic-modal-section">
                <h3 className="mosaic-modal-section-title">Color</h3>
                <div className="mosaic-modal-options">
                  {colors.map((color) => (
                    <label
                      key={color.name}
                      className={classNames(
                        selectedColor && selectedColor.name === color.name
                          ? 'mosaic-modal-option-selected'
                          : '',
                        'mosaic-modal-option'
                      )}
                    >
                      <input
                        type="radio"
                        name="color"
                        value={color.name}
                        checked={selectedColor && selectedColor.name === color.name}
                        onChange={() => handleColorChange(color)}
                        className="mosaic-modal-option-input"
                      />
                      <span
                        className="mosaic-modal-option-color"
                        style={{ backgroundColor: color.bgColor }}
                        aria-label={color.name}
                      ></span>
                      <span className="mosaic-modal-option-name">{color.name}</span>
                    </label>
                  ))}
                </div>
              </div>
            )}

            {/* Size Picker */}
            {isVariableProduct && sizes.length > 0 && (
              <div className="mosaic-modal-section">
                <h3 className="mosaic-modal-section-title">Size</h3>
                <div className="mosaic-modal-options">
                  {sizes.map((size) => (
                    <label
                      key={size.name}
                      className={classNames(
                        selectedSize && selectedSize.name === size.name
                          ? 'mosaic-modal-option-selected'
                          : '',
                        size.inStock ? 'mosaic-modal-option' : 'mosaic-modal-option-disabled'
                      )}
                    >
                      <input
                        type="radio"
                        name="size"
                        value={size.name}
                        checked={selectedSize && selectedSize.name === size.name}
                        onChange={() => handleSizeChange(size)}
                        disabled={!size.inStock}
                        className="mosaic-modal-option-input"
                      />
                      <span className="mosaic-modal-option-name">{size.name}</span>
                    </label>
                  ))}
                </div>
              </div>
            )}

            {/* Quantity Selector */}
            <div className="mosaic-modal-quantity-selector">
              <button
                onClick={() => handleQuantityChange(-1)}
                className="mosaic-modal-quantity-button"
                aria-label="Decrease quantity"
              >
                -
              </button>
              <span className="mosaic-modal-quantity">{quantity}</span>
              <button
                onClick={() => handleQuantityChange(1)}
                className="mosaic-modal-quantity-button"
                aria-label="Increase quantity"
                disabled={
                  isVariableProduct
                    ? quantity >=
                      (product.variants.find(
                        (variant) =>
                          variant.color === (selectedColor ? selectedColor.name : '') &&
                          variant.size === (selectedSize ? selectedSize.name : '')
                      )?.stock || 1)
                    : quantity >= (product.stock || 1)
                }
              >
                +
              </button>
            </div>

            {/* Add to Bag Button */}
            <button className="mosaic-modal-add-to-bag-button" onClick={handleAddToCart}>
              <AiOutlineShoppingCart size={20} style={{ marginRight: '5px' }} />
              Add to Cart
            </button>

            {/* View Full Details Button */}
            <div className="mosaic-modal-full-details">
              <button className="mosaic-modal-view-details-button" onClick={handleViewDetails}>
                View full details
              </button>
            </div>
          </div>
        </div>

        {/* Reviews Section */}
        {product.reviews && product.reviews.length > 0 && (
          <div className="mosaic-modal-reviews-section">
            <h3>Customer Reviews</h3>
            {product.reviews.map((review) => (
              <div key={review._id} className="mosaic-modal-review">
                <p className="mosaic-modal-reviewer">
                  <strong>{review.reviewerName || 'Anonymous'}</strong>
                </p>
                <p className="mosaic-modal-review-comment">{review.comment}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickViewModal;
