import React, { useEffect, useState } from "react";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfUser } from "../redux/actions/order";
import { ecommerceServer } from "../server";
import { RxCross1 } from "react-icons/rx";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";

const UserOrderDetails = () => {
  const { orders } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(1);

  const { id } = useParams();

  useEffect(() => {
    if (user && user._id) {
      dispatch(getAllOrdersOfUser(user._id));
    }
  }, [dispatch, user._id]);

  const data = orders && orders.find((item) => item._id === id);

  const reviewHandler = async () => {
    if (!selectedItem) {
      toast.error("No product selected.");
      return;
    }

    try {
      const response = await axios.put(
        `${ecommerceServer}/product/create-new-review`,
        {
          reviewer: user._id,
          reviewerModel: 'User',
          rating,
          comment,
          productId: selectedItem._id,
          orderId: id,
        },
        { withCredentials: true }
      );
      toast.success(response.data.message);
      dispatch(getAllOrdersOfUser(user._id));
      setComment("");
      setRating(null);
      setOpen(false);
    } catch (error) {
      console.error("Failed to submit review:", error);
      toast.error(error.response ? error.response.data.message : "Failed to submit review");
    }
  };

  const refundHandler = async () => {
    try {
      const res = await axios.put(`${ecommerceServer}/order/order-refund/${id}`, {
        status: "Processing refund"
      }, { withCredentials: true });
      toast.success(res.data.message);
      dispatch(getAllOrdersOfUser(user._id));
    } catch (error) {
      toast.error(error.response ? error.response.data.message : "Failed to process refund");
    }
  };

  return (
    <div className="user-order-details-container">
      <div className="user-order-details-header">
        <div className="user-order-details-header-left">
          <BsFillBagFill size={30} color="crimson" />
          <h1 className="user-order-details-title">Order Details</h1>
        </div>
      </div>

      <div className="user-order-details-info">
        <h5 className="user-order-details-info-text">
          Order ID: <span>#{data?._id?.slice(0, 8)}</span>
        </h5>
        <h5 className="user-order-details-info-text">
          Placed on: <span>{data?.createdAt?.slice(0, 10)}</span>
        </h5>
      </div>

      <br />
      <br />
      {data && data.cart && data.cart.map((item, index) => (
        <div key={index} className="user-order-details-item">
          <img
            src={item.images}
            alt={item.productName}
            className="user-order-details-item-image"
          />
          <div className="user-order-details-item-details">
            <h5 className="user-order-details-item-name">{item.productName}</h5>
            <h5 className="user-order-details-item-price">
              US${item.unitAmount / 100} x {item.quantity}
            </h5>
          </div>
          {!item.isReviewed && data?.status === "Delivered" ? (
            <div
              className="user-order-details-review-button"
              onClick={() => setOpen(true) || setSelectedItem(item)}
            >
              Write a review
            </div>
          ) : null}
        </div>
      ))}

      {open && (
        <div className="user-order-details-modal">
          <div className="user-order-details-modal-content">
            <div className="user-order-details-modal-close">
              <RxCross1
                size={30}
                onClick={() => setOpen(false)}
                className="user-order-details-close-icon"
              />
            </div>
            <h2 className="user-order-details-modal-title">
              Give a Review
            </h2>
            <br />
            <div className="user-order-details-modal-body">
              <img
                src={`${selectedItem?.images}`}
                alt=""
                className="user-order-details-modal-image"
              />
              <div>
                <div className="user-order-details-modal-item-name">{selectedItem?.productName}</div>
                <h4 className="user-order-details-modal-item-price">
                  US${selectedItem?.unitAmount / 100} x {selectedItem?.quantity}
                </h4>
              </div>
            </div>

            <br />
            <br />

            <h5 className="user-order-details-rating-label">
              Give a Rating <span className="user-order-details-rating-required">*</span>
            </h5>
            <div className="user-order-details-rating">
              {[1, 2, 3, 4, 5].map((i) =>
                rating >= i ? (
                  <AiFillStar
                    key={i}
                    className="user-order-details-star-icon"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                ) : (
                  <AiOutlineStar
                    key={i}
                    className="user-order-details-star-icon"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                )
              )}
            </div>
            <br />
            <div className="user-order-details-comment">
              <label className="user-order-details-comment-label">
                Write a comment
                <span className="user-order-details-comment-optional">(optional)</span>
              </label>
              <textarea
                name="comment"
                id=""
                cols="20"
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="How was your product? Write your expression about it!"
                className="user-order-details-textarea"
              ></textarea>
            </div>
            <div
              className="user-order-details-submit-button"
              onClick={rating > 1 ? reviewHandler : null}
            >
              Submit
            </div>
          </div>
        </div>
      )}

      <div className="user-order-details-total">
        <h5 className="user-order-details-total-text">
          Total Price: <strong>US${data?.totalPrice}</strong>
        </h5>
      </div>
      <br />
      <br />
      <div className="user-order-details-shipping-payment">
        <div className="user-order-details-shipping">
          <h4 className="user-order-details-shipping-title">Shipping Address:</h4>
          <h4 className="user-order-details-shipping-address">
            {data?.shippingAddress.address1 + " " + data?.shippingAddress.address2}
          </h4>
          <h4 className="user-order-details-shipping-location">{data?.shippingAddress.country}</h4>
          <h4 className="user-order-details-shipping-location">{data?.shippingAddress.city}</h4>
          <h4 className="user-order-details-shipping-location">{data?.shippingAddress.state}</h4>
        </div>
        <div className="user-order-details-payment">
          <h4 className="user-order-details-payment-title">Payment Info:</h4>
          <h4 className="user-order-details-payment-status">
            Status: {data?.paymentInfo?.status ? data?.paymentInfo?.status : "Not Paid"}
          </h4>
          <br />
          {data?.status === "Delivered" && (
            <div className="user-order-details-refund-button" onClick={refundHandler}>
              Give a Refund
            </div>
          )}
        </div>
      </div>
      <br />
      <Link to="/">
        <div className="user-order-details-message-button">Send Message</div>
      </Link>
      <br />
      <br />
    </div>
  );
};

export default UserOrderDetails;

