import React, { useState, useEffect } from "react";
import {
  AiFillHeart,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";

const ProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState(data?.variants ? data.variants[0] : null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }

    // Set default variant on load
    if (data?.variants?.length > 0) {
      setSelectedVariant(data.variants[0]);
    }
  }, [wishlist, data]);

  const handleQuantityChange = (event, amount) => {
    event.stopPropagation();
    if (amount < 0) {
      setQuantity(Math.max(1, quantity + amount));
    } else {
      setQuantity(Math.min(selectedVariant?.stock || data.stock, quantity + amount));
    }
  };

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id && i.variant?._id === selectedVariant?._id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (selectedVariant?.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = {
          ...data,
          qty: quantity,
          variant: selectedVariant,
          price: selectedVariant?.price || data.discountPrice,
        };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  // Handle color variant selection
  const handleColorChange = (color) => {
    const matchingVariant = data.variants.find((variant) => variant.color === color);
    if (matchingVariant) {
      setSelectedVariant(matchingVariant);
      setQuantity(1); // Reset quantity to 1 when variant is changed
    }
  };

  return (
    <>
      <div className="product-card-component-container">
        
        <Link to={`${isEvent === true ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
          <img src={`${selectedVariant?.image?.url || data.images?.[0]?.url}`} alt="" className="product-card-component-image" />
          <div className="product-card-component-info-container">
          
          <h4 className="product-card-component-product-name">
            {data.name.length > 40 ? data.name.slice(0, 40) + "..." : data.name}
          </h4>
          <div className="product-card-component-shop">
              <img
                  className="product-card-component-shop-avatar"
                  src={data.shop?.avatar?.url}
                  alt={`${data.shop.name}'s avatar`}
              />
              <h5 className="product-card-component-shop-name">{data?.shop.name}</h5>
          </div>

        {data?.ratings ? (
          <div className="product-card-component-ratings">
            <Ratings rating={data.ratings} />
          </div>
        ) : null}

          <div className="product-card-component-price-section">
            <div className="product-card-component-price">
              {data.isOnSale ? (
                <>
                  <h5 className="product-card-component-discount-price">
                    ${selectedVariant?.price || data.discountPrice}
                  </h5>
                  <h4 className="product-card-component-original-price">
                    ${selectedVariant?.originalPrice || data.originalPrice}
                  </h4>
                </>
              ) : (
                <h5 className="product-card-component-regular-price">
                  ${selectedVariant?.price || data.originalPrice || data.discountPrice}
                </h5>
              )}
            </div>
          </div>

          </div>
        </Link>

        {data?.variants?.some((variant) => variant.color) &&  (
          <div className="product-card-component-color-swatches">
            {[
              ...new Map(
                data.variants
                  .filter((variant) => variant.color)
                  .map((variant) => [variant.color, variant]) // Remove duplicate colors
              ).values(),
            ].map((variant, index) => (
              <div
                key={index}
                className={`product-card-component-color-swatch ${selectedVariant?.color === variant.color ? "active" : ""}`}
                style={{ backgroundColor: variant.colorSwatch }}
                onClick={() => handleColorChange(variant.color)}
              />
            ))}
          </div>
        )}

        <div className="product-card-component-quantity-selector"> 
          <button 
            onClick={(e) => handleQuantityChange(e, -1)} 
            className="product-card-component-quantity-button">
            -
          </button>
          <span className="product-card-component-quantity">{quantity}</span>
          <button 
            onClick={(e) => handleQuantityChange(e, 1)} 
            className="product-card-component-quantity-button" 
            disabled={quantity >= (selectedVariant?.stock || data.stock)}>
            +
          </button>
          <AiOutlineShoppingCart
            size={25}
            className="product-card-component-cart-icon"
            onClick={(e) => {
              e.stopPropagation();
              addToCartHandler(data._id);
            }}
            color="#444"
            title="Add to cart"
          />
        </div>
        

       
        

        <div className="product-card-component-wishlist-icon">
          {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : "#fff"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "#fff"}
              title="Add to wishlist"
            />
          )}
        </div>

        <div className="product-card-component-eye-icon">
          <AiOutlineEye
            size={22}
            className="cursor-pointer"
            onClick={() => setOpen(!open)}
            color="#fff"
            title="Quick view"
          />
        </div>

        {open && <ProductDetailsCard setOpen={setOpen} data={data} />}

        {data.brandTypeSelection === 'branded' && (
          <div className="product-card-component-brand-badge">
            <img
              src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1710252613/feat_morfbr.png"
              alt="Featured Brand"
              className="product-card-component-brand-image"
            />
          </div>
        )}

      </div>
    </>
  );
};

export default ProductCard;



