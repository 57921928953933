import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import PostsWidgetRedux from "../widgets/PostsWidgetRedux";
import AboutUser from "../components/AboutUser";
import MyPostWidgetRedux from "../widgets/MyPostWidgetRedux";
import ConnectionsWidget from "./Followers/ConnectionsWidget";
import { DocumentTextIcon, UserIcon, UsersIcon, PhotoIcon, CalendarIcon } from '@heroicons/react/24/outline';
import OtherConnectionsWidget from "./Connections/OtherConnectionsWidget";
import MyImages from "./Content/MyImages";
import MyEvents from "./Events/MyEvents";
import MyEventsByOwner from "./Events/MyEventsByOwner";
import EventsParentContainer from "./Events/EventsParentContainer";
import MyImagesProfile from "./Content/MyImagesProfile";


const UserProfileData = () => {
  const { user } = useSelector((state) => state.user);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('Posts');
  const picturePath = user?.picturePath;

  useEffect(() => {
    // Dispatch actions to fetch user-related data (posts, friends, etc.)
    // Example: dispatch(getUserPosts(id));
    // dispatch(getUserFriends(id));
  }, [dispatch, id]);

  const tabs = [
    { name: 'Posts', icon: DocumentTextIcon, current: activeTab === 'Posts' },
    { name: 'Images', icon: PhotoIcon, current: activeTab === 'Images' },
    { name: 'Connections', icon: UsersIcon, current: activeTab === 'Connections' },
    { name: 'Events', icon: CalendarIcon, current: activeTab === 'Events' },
    { name: 'About', icon: UserIcon, current: activeTab === 'About' },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className="user-profile-data-content-container">
      {/* Tab navigation */}
      <div className="user-profile-data-tab-navigation">
        <nav className="user-profile-data-tabs" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => setActiveTab(tab.name)}
              className={classNames(
                tab.current ? 'user-profile-data-tab-active' : 'user-profile-data-tab-inactive',
                'user-profile-data-tab-button'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              <tab.icon className="user-profile-data-tab-icon" />
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      {activeTab === 'Posts' && (
        <div className="user-profile-data-tab-content">
          <PostsWidgetRedux ownerId={id} profileType="User" isProfile={true} />
        </div>
      )}

      {activeTab === 'About' && (
        <div className="user-profile-data-tab-content">
          <AboutUser userId={id} />
        </div>
      )}

      {activeTab === 'Connections' && (
        <div className="user-profile-data-tab-content">
          <OtherConnectionsWidget userId={id} profileType="User" />
        </div>
      )}

      {activeTab === 'Images' && (
        <div className="user-profile-data-tab-content">
          <MyImagesProfile userId={id} />
        </div>
      )}
    {activeTab === 'Events' && (
        <div className="user-profile-data-tab-content">
          <EventsParentContainer ownerId={id} />
        </div>
      )}
    </div>
  );
};

export default UserProfileData;
