import React from 'react';
import { Link } from 'react-router-dom';


const AuthenticationPrompt = () => {
  return (
    <div className="authentification-prompt-container" >
      <h2 className="authentification-prompt-title">Welcome to Our Community!</h2>
      <p className="authentification-prompt-message">
        Please sign in or sign up to view and interact with posts.
      </p>
      <div className="authentification-prompt-buttonContainer">
        <Link to="/login" className="authentification-prompt-button">
          Sign In
        </Link>
        <Link to="/signup" className="authentification-prompt-buttonOutline">
          Sign Up
        </Link>
      </div>
    </div>
  );
};

export default AuthenticationPrompt;
