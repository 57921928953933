import { createReducer } from "@reduxjs/toolkit";
import {
  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  CREATE_POST_FAILURE,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  UPLOAD_VIDEO_REQUEST,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILURE,
  LIKE_POST_SUCCESS,
  POST_COMMENT_SUCCESS,
  EDIT_POST_SUCCESS,
  EDIT_COMMENT_SUCCESS,
  DELETE_POST_SUCCESS,
  DELETE_COMMENT_SUCCESS,
  FETCH_COMMENTS_SUCCESS,
  OPERATION_FAIL,
  OPERATION_REQUEST,
  OPERATION_COMPLETE,
  CLEAR_ERRORS,
  FETCH_POSTS_REQUEST, 
  FETCH_POSTS_SUCCESS, 
  FETCH_POSTS_FAILURE,
  RESHARE_POST_REQUEST,
  RESHARE_POST_SUCCESS,
  RESHARE_POST_FAILURE,
  FETCH_LIKES_SUCCESS,
  REPORT_POST_REQUEST,
  REPORT_POST_SUCCESS,
  REPORT_POST_FAILURE,
  FETCH_REPORTED_POSTS_REQUEST,
  FETCH_REPORTED_POSTS_SUCCESS,
  FETCH_REPORTED_POSTS_FAILURE,
  FETCH_SINGLE_POST_REQUEST,
  FETCH_SINGLE_POST_SUCCESS,
  FETCH_SINGLE_POST_FAILURE,
  FETCH_SINGLE_POST_COMMENTS_REQUEST,
  FETCH_SINGLE_POST_COMMENTS_SUCCESS, 
  FETCH_SINGLE_POST_COMMENTS_FAIL,
  FETCH_SINGLE_POST_LIKES_REQUEST,
  FETCH_SINGLE_POST_LIKES_SUCCESS, 
  FETCH_SINGLE_POST_LIKES_FAIL,
  FETCH_TRENDING_POST_REQUEST,
  FETCH_TRENDING_POST_SUCCESS,
  FETCH_TRENDING_POST_FAILURE,
  CLEAR_POSTS,
} from '../actions/post'; 

const initialState = {
  posts: [],
  singlePost: null,
  trendingPost: null,
  singlePostComments: [],
  singlePostLikes: {},
  trendingPost: null,
  trendingPostLikes: [],
  trendingPostComments: [],
  hashtagposts: [],
  comments: {},
  likes: {},
  hashtags: [],
  reportedPosts: [],
  // General error state
  error: null,
  // Individual loading states
  createPostLoading: false,
  uploadImageLoading: false,
  uploadVideoLoading: false,
  resharePostLoading: false,
  fetchPostsLoading: false,
  fetchSinglePostLoading: false,
  fetchSinglePostCommentsLoading: false,
  fetchSinglePostLikesLoading: false,
  reportPostLoading: false,
  fetchReportedPostsLoading: false,
  fetchTrendingPostLoading: false,
};

export const postsReducer = createReducer(initialState, {

  // Create Post
  [CREATE_POST_REQUEST]: (state) => {
    state.createPostLoading = true;
    state.error = null;
  },
  [CREATE_POST_SUCCESS]: (state, action) => {
    state.createPostLoading = false;
    state.posts.unshift(action.payload); 
    state.error = null;
  },
  [CREATE_POST_FAILURE]: (state, action) => {
    state.createPostLoading = false;
    state.error = action.payload;
  },

  // Upload Image
  [UPLOAD_IMAGE_REQUEST]: (state) => {
    state.uploadImageLoading = true;
    state.error = null;
  },
  [UPLOAD_IMAGE_SUCCESS]: (state, action) => {
    state.uploadImageLoading = false;
    state.imageUploadUrl = action.payload; 
    state.error = null;
  },
  [UPLOAD_IMAGE_FAILURE]: (state, action) => {
    state.uploadImageLoading = false;
    state.error = action.payload;
  },

  // Upload Video
  [UPLOAD_VIDEO_REQUEST]: (state) => {
    state.uploadVideoLoading = true;
    state.error = null;
  },
  [UPLOAD_VIDEO_SUCCESS]: (state, action) => {
    state.uploadVideoLoading = false;
    state.videoUploadUrl = action.payload; 
    state.error = null;
  },
  [UPLOAD_VIDEO_FAILURE]: (state, action) => {
    state.uploadVideoLoading = false;
    state.error = action.payload;
  },

  // Reshare Post
  [RESHARE_POST_REQUEST]: (state) => {
    state.resharePostLoading = true;
    state.error = null;
  },
  [RESHARE_POST_SUCCESS]: (state, action) => {
    state.resharePostLoading = false;
    const resharedPost = {
      ...action.payload,
      likes: {}, 
      comments: [] 
    };
    state.posts.unshift(resharedPost);
    state.error = null;
  },
  [RESHARE_POST_FAILURE]: (state, action) => {
    state.resharePostLoading = false;
    state.error = action.payload;
  },

  // Fetch Posts
  [FETCH_POSTS_REQUEST]: (state) => {
    state.fetchPostsLoading = true;
    state.error = null;
  },
  [FETCH_POSTS_SUCCESS]: (state, action) => {
    state.fetchPostsLoading = false;
    const newPosts = action.payload.posts.filter(
      post => !state.posts.some(p => p._id === post._id)
    );
    state.posts = [...state.posts, ...newPosts];
    state.page = action.payload.page;
    state.totalPages = action.payload.totalPages;
    state.error = null;
  },
  [FETCH_POSTS_FAILURE]: (state, action) => {
    state.fetchPostsLoading = false;
    state.error = action.payload; 
  },

  // Fetch Single Post
  [FETCH_SINGLE_POST_REQUEST]: (state) => {
    state.fetchSinglePostLoading = true;
    state.error = null;
    state.singlePost = null; 
  },
  [FETCH_SINGLE_POST_SUCCESS]: (state, action) => {
    state.fetchSinglePostLoading = false;
    state.singlePost = action.payload; 
    state.error = null;
  },
  [FETCH_SINGLE_POST_FAILURE]: (state, action) => {
    state.fetchSinglePostLoading = false;
    state.error = action.payload?.message || "Failed to fetch the post.";
  },

  // Fetch Single Post Comments
  [FETCH_SINGLE_POST_COMMENTS_REQUEST]: (state) => {
    state.fetchSinglePostCommentsLoading = true;
    state.error = null;
  },
  [FETCH_SINGLE_POST_COMMENTS_SUCCESS]: (state, action) => {
    const { comments } = action.payload;
    state.singlePostComments = comments;
    state.fetchSinglePostCommentsLoading = false;
    state.error = null;
  },
  [FETCH_SINGLE_POST_COMMENTS_FAIL]: (state, action) => {
    state.fetchSinglePostCommentsLoading = false;
    state.error = action.payload?.message || "Failed to load comments.";
  },

  // Fetch Single Post Likes
  [FETCH_SINGLE_POST_LIKES_REQUEST]: (state) => {
    state.fetchSinglePostLikesLoading = true;
    state.error = null;
  },
  [FETCH_SINGLE_POST_LIKES_SUCCESS]: (state, action) => {
    const { postId, likes } = action.payload;
    state.singlePostLikes[postId] = likes;
    state.fetchSinglePostLikesLoading = false;
    state.error = null;
  },
  [FETCH_SINGLE_POST_LIKES_FAIL]: (state, action) => {
    state.fetchSinglePostLikesLoading = false;
    state.error = action.payload;
  },

  // Report Post
  [REPORT_POST_REQUEST]: (state) => {
    state.reportPostLoading = true;
    state.error = null;
  },
  [REPORT_POST_SUCCESS]: (state) => {
    state.reportPostLoading = false;
    state.error = null;
  },
  [REPORT_POST_FAILURE]: (state, action) => {
    state.reportPostLoading = false;
    state.error = action.payload;
  },

  // Fetch Reported Posts
  [FETCH_REPORTED_POSTS_REQUEST]: (state) => {
    state.fetchReportedPostsLoading = true;
    state.error = null;
  },
  [FETCH_REPORTED_POSTS_SUCCESS]: (state, action) => {
    state.fetchReportedPostsLoading = false;
    state.reportedPosts = action.payload;
    state.error = null;
  },
  [FETCH_REPORTED_POSTS_FAILURE]: (state, action) => {
    state.fetchReportedPostsLoading = false;
    state.error = action.payload;
  },

  // Fetch Trending Post
  [FETCH_TRENDING_POST_REQUEST]: (state) => {
    state.fetchTrendingPostLoading = true;
    state.error = null;
    state.trendingPost = null; 
  },
  [FETCH_TRENDING_POST_SUCCESS]: (state, action) => {
    state.fetchTrendingPostLoading = false;
    state.trendingPost = action.payload;
    state.error = null;
  },
  [FETCH_TRENDING_POST_FAILURE]: (state, action) => {
    state.fetchTrendingPostLoading = false;
    state.error = action.payload;
  },

  // Clear Posts
  [CLEAR_POSTS]: (state) => {
    state.posts = [];
  },

  // Like Post
  [LIKE_POST_SUCCESS]: (state, action) => {
    const { postId, likes } = action.payload;
    const index = state.posts.findIndex(post => post._id === postId);
    if (index !== -1) {
      state.posts[index] = { ...state.posts[index], likes };
      state.likes[postId] = likes; 
    }
  },
  [FETCH_LIKES_SUCCESS]: (state, action) => {
    const { postId, likes } = action.payload;
    state.likes[postId] = likes;
  },

  // Post Comment
  [POST_COMMENT_SUCCESS]: (state, action) => {
    const { postId, comment } = action.payload;
    if (!state.comments[postId]) {
      state.comments[postId] = [comment];
    } else {
      state.comments[postId].push(comment);
    }
  },

  // Edit Post
  [EDIT_POST_SUCCESS]: (state, action) => {
    const index = state.posts.findIndex(post => post._id === action.payload._id);
    if (index !== -1) {
      state.posts[index] = { ...state.posts[index], ...action.payload };
    }
  },

  // Edit Comment
  [EDIT_COMMENT_SUCCESS]: (state, action) => {
    const { postId, commentId, updatedComment } = action.payload;
    if (state.comments[postId]) {
      const index = state.comments[postId].findIndex(comment => comment._id === commentId);
      if (index !== -1) {
        state.comments[postId][index] = updatedComment; 
      }
    }
  },

  // Delete Post
  [DELETE_POST_SUCCESS]: (state, action) => {
    const postId = action.payload;
    state.posts = state.posts.filter(post => post._id !== postId); 
    delete state.comments[postId]; 
  },

  // Delete Comment
  [DELETE_COMMENT_SUCCESS]: (state, action) => {
    const { postId, commentId } = action.payload;
    if (state.comments[postId]) {
      state.comments[postId] = state.comments[postId].filter(
        comment => comment._id !== commentId
      );
    }
  },

  // Fetch Comments
  [FETCH_COMMENTS_SUCCESS]: (state, action) => {
    const { postId, comments } = action.payload;
    state.comments[postId] = comments; 
  },
  [CLEAR_ERRORS]: (state) => {
    state.error = null; 
  },
  [OPERATION_FAIL]: (state, action) => {
    state.error = action.payload; 
  },
  [OPERATION_REQUEST]: (state) => {
    state.loading = true; 
  },
  [OPERATION_COMPLETE]: (state) => {
    state.loading = false;
    state.error = null; 
  },
});



