import React, { useEffect, useState } from "react";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllPurchasesOfShop } from "../redux/actions/order";
import { ecommerceServer } from "../server";
import { RxCross1 } from "react-icons/rx";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";


const SellerOrderDetails = () => {
  const { shopPurchases } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(1);

  const { id } = useParams();

  useEffect(() => {
    if (seller && seller._id) {
      dispatch(getAllPurchasesOfShop(seller._id));
    }
  }, [dispatch, seller._id]);

  const data = shopPurchases && shopPurchases.find((item) => item._id === id);

  const reviewHandler = async () => {
    if (!selectedItem) {
      toast.error("No product selected.");
      return;
    }

    try {
      const response = await axios.put(
        `${ecommerceServer}/product/create-new-review`,
        {
          reviewer: seller._id,
          reviewerModel: 'Shop',
          rating,
          comment,
          productId: selectedItem._id,
          orderId: id,
        },
        { withCredentials: true }
      );
      toast.success(response.data.message);
      dispatch(getAllPurchasesOfShop(seller._id));
      setComment("");
      setRating(null);
      setOpen(false);
    } catch (error) {
      console.error("Failed to submit review:", error);
      toast.error(error.response ? error.response.data.message : "Failed to submit review");
    }
  };

  const refundHandler = async () => {
    try {
      const res = await axios.put(`${ecommerceServer}/order/order-refund/${id}`, {
        status: "Processing refund"
      }, { withCredentials: true });
      toast.success(res.data.message);
      dispatch(getAllPurchasesOfShop(seller._id));
    } catch (error) {
      toast.error(error.response ? error.response.data.message : "Failed to process refund");
    }
  };

  return (
    <div className="seller-order-details-component-container">
      <div className="seller-order-details-component-header">
        <div className="seller-order-details-component-header-left">
          <BsFillBagFill size={30} color="crimson" />
          <h1 className="seller-order-details-component-title">Order Details</h1>
        </div>
      </div>

      <div className="seller-order-details-component-info">
        <h5 className="seller-order-details-component-info-text">
          Order ID: <span>#{data?._id?.slice(0, 8)}</span>
        </h5>
        <h5 className="seller-order-details-component-info-text">
          Placed on: <span>{data?.createdAt?.slice(0, 10)}</span>
        </h5>
      </div>

      <br />
      <br />
      {data && data.cart && data.cart.map((item, index) => (
        <div key={index} className="seller-order-details-component-item">
          <img
            src={item.images}
            alt={item.productName}
            className="seller-order-details-component-item-image"
          />
          <div className="seller-order-details-component-item-details">
            <h5 className="seller-order-details-component-item-name">{item.productName}</h5>
            <h5 className="seller-order-details-component-item-price">
              US${item.unitAmount / 100} x {item.quantity}
            </h5>
          </div>
          {!item.isReviewed && data?.status === "Delivered" ? (
            <div
              className="seller-order-details-component-review-button"
              onClick={() => setOpen(true) || setSelectedItem(item)}
            >
              Write a review
            </div>
          ) : null}
        </div>
      ))}

      {open && (
        <div className="seller-order-details-component-modal">
          <div className="seller-order-details-component-modal-content">
            <div className="seller-order-details-component-modal-close">
              <RxCross1
                size={30}
                onClick={() => setOpen(false)}
                className="seller-order-details-component-close-icon"
              />
            </div>
            <h2 className="seller-order-details-component-modal-title">
              Give a Review
            </h2>
            <br />
            <div className="seller-order-details-component-modal-body">
              <img
                src={`${selectedItem?.images[0]}`}
                alt=""
                className="seller-order-details-component-modal-image"
              />
              <div>
                <div className="seller-order-details-component-modal-item-name">{selectedItem?.productName}</div>
                <h4 className="seller-order-details-component-modal-item-price">
                  US${selectedItem?.unitAmount / 100} x {selectedItem?.quantity}
                </h4>
              </div>
            </div>

            <br />
            <br />

            <h5 className="seller-order-details-component-rating-label">
              Give a Rating <span className="seller-order-details-component-rating-required">*</span>
            </h5>
            <div className="seller-order-details-component-rating">
              {[1, 2, 3, 4, 5].map((i) =>
                rating >= i ? (
                  <AiFillStar
                    key={i}
                    className="seller-order-details-component-star-icon"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                ) : (
                  <AiOutlineStar
                    key={i}
                    className="seller-order-details-component-star-icon"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                )
              )}
            </div>
            <br />
            <div className="seller-order-details-component-comment">
              <label className="seller-order-details-component-comment-label">
                Write a comment
                <span className="seller-order-details-component-comment-optional">(optional)</span>
              </label>
              <textarea
                name="comment"
                id=""
                cols="20"
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="How was your product? Write your expression about it!"
                className="seller-order-details-component-textarea"
              ></textarea>
            </div>
            <div
              className="seller-order-details-component-submit-button"
              onClick={rating > 1 ? reviewHandler : null}
            >
              Submit
            </div>
          </div>
        </div>
      )}

      <div className="seller-order-details-component-total">
        <h5 className="seller-order-details-component-total-text">
          Total Price: <strong>US${data?.totalPrice}</strong>
        </h5>
      </div>
      <br />
      <br />
      <div className="seller-order-details-component-shipping-payment">
        <div className="seller-order-details-component-shipping">
          <h4 className="seller-order-details-component-shipping-title">Shipping Address:</h4>
          <h4 className="seller-order-details-component-shipping-address">
            {data?.shippingAddress.address1 + " " + data?.shippingAddress.address2}
          </h4>
          <h4 className="seller-order-details-component-shipping-location">{data?.shippingAddress.country}</h4>
          <h4 className="seller-order-details-component-shipping-location">{data?.shippingAddress.city}</h4>
          <h4 className="seller-order-details-component-shipping-location">{data?.shippingAddress.state}</h4>
        </div>
        <div className="seller-order-details-component-payment">
          <h4 className="seller-order-details-component-payment-title">Payment Info:</h4>
          <h4 className="seller-order-details-component-payment-status">
            Status: {data?.paymentInfo?.status ? data?.paymentInfo?.status : "Not Paid"}
          </h4>
          <br />
          {data?.status === "Delivered" && (
            <div className="seller-order-details-component-refund-button" onClick={refundHandler}>
              Give a Refund
            </div>
          )}
        </div>
      </div>
      <br />
      <Link to="/">
        <div className="seller-order-details-component-message-button">Send Message</div>
      </Link>
      <br />
      <br />
    </div>
  );
};

export default SellerOrderDetails;
