import React from 'react';
import { useNavigate } from 'react-router-dom';

const TagScroller = ({ tags, onTagClick }) => {

  return (
    <div className="tag-scroller-container">
      {tags.map((tag) => (
        <div
          key={tag._id}
          className="tag-menu-item"
          onClick={() => onTagClick(tag)}
        >
          {tag.name}
        </div>
      ))}
    </div>
  );
};

export default TagScroller;
