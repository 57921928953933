import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../server'; 
import Header from '../components/Layout/Header';
import Sidebar from '../components/Layout/Sidebar';
import UserBanner from '../components/Shop/UserBanner';
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import UserProfileData from '../components/UserProfileData';
import MyImages from '../components/Content/MyImages';
import MyEvents from '../components/Events/MyEvents';
import MyContentCarousel from '../components/Profile/MyContentCarousel';
import FollowButton from '../components/Connections/FollowButton';
import FollowersDisplay from '../components/Connections/FollowersDisplay';
import MyPostWidgetRedux from '../widgets/MyPostWidgetRedux';
import MixedSponsoredAds from '../components/Sponsored/MixedSponsoredAds';
import RoomsProfile from '../components/Rooms/RoomsProfile';
import UserAvatar from '../components/Layout/UserAvatar';
import FollowingDisplay from '../components/Connections/FollowingDisplay';
import Loader from '../components/Layout/Loader';


const PublicProfilePage = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${server}/user/user-info/${id}`);
        setUser(response.data.user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserData();
  }, [id]);

  if (isLoading) {
    return <Loader />;
  }

  if (!user) {
    return <div>User data not available</div>;
  }

return (
  <div className="profile-page">
    <Header />
    <Sidebar />
     <div className="profile-banner-wrapper">
        <UserBanner isUser={false} user={user} setUser={setUser} />
        <div className="profile-info-container">
          <div className="profile-avatar-wrapper">
            <UserAvatar isUser={false} user={user} setUser={setUser} />
            <div className="profile-info">
              <div className="profile-name-wrapper">
                <h3 className="profile-name">{user.name}</h3>
                <h3 className="profile-handle">@{user.handle}</h3>
                <div className="profile-friends">
                  <FollowersDisplay userId={id} profileType="User" />
                  <FollowingDisplay userId={id} profileType="User" />
                </div>
              </div>
              <FollowButton className="profile-followers-add-button" connectionId={id} connectionType="User" />
            </div>
          </div>
        </div>
      </div>
    <div className="profile-main-content">
      <div className="profile-sidebar-left">
      <RoomsProfile profileType="User" profileId={id}/>
        <MyImages userId={id} />
        <MyEvents />
      </div>
      <main className="profile-main-area">
        <MyPostWidgetRedux />
        <UserProfileData />
      </main>
      <aside className="profile-sidebar-right">
      <MyContentCarousel ownerId={id} profileType="User" /> 
        <MixedSponsoredAds />
      </aside>
    </div>
  </div>
);
};

export default PublicProfilePage;

