import React from 'react';

function MemeGallery({ memes, adjustFontSize }) { 
  const renderMeme = (meme) => (
    <div key={meme._id} className="meme-gallery-item" style={{ position: 'relative' }}>
      <img
        src={meme.imageUrl}
        alt="Meme"
        className="meme-gallery-image"
        onLoad={(e) => adjustFontSize(e.target, meme)}
      />
      <div
        style={{
          position: 'absolute',
          top: `${meme.textPositionY}%`,
          left: `${meme.textPositionX}%`,
          width: `${meme.textWidth}%`,
          height: `${meme.textHeight}%`,
          color: meme.fontColor,
          fontFamily: meme.fontFamily,
          zIndex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          lineHeight: '1',
          wordWrap: 'break-word',
          overflow: 'hidden',
        }}
      >
        {meme.overlayText}
      </div>
    </div>
  );

  return (
    <div className="meme-gallery">
      {memes.length === 0 ? (
        <div className="meme-gallery-placeholder">
          <img src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/6cc98ecc-5bda-4edd-1e30-1895bb0e5c00/public" alt="No memes" className="placeholder-image" />
          <p className="placeholder-text">No memes have been created yet. Be the first to share a laugh!</p>
        </div>
      ) : (
        renderMeme(memes[0]) 
      )}
    </div>
  );
}

export default MemeGallery;

