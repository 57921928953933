import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, removeFromWishlist } from '../../redux/actions/wishlist';
import { toast } from 'react-toastify';

// Importing React Icons for Wishlist and Cart
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import QuickViewModal from './QuickViewModal';

const ProductInfiniteMosaic = ({ allProducts }) => {
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [filterCategory, setFilterCategory] = useState('All');
  const [sortOption, setSortOption] = useState('Newest');
  const [hasMore, setHasMore] = useState(true);
  const [currentCount, setCurrentCount] = useState(0);
  const productsPerLoad = 20;

  const dispatch = useDispatch();
  const { wishlist } = useSelector((state) => state.wishlist);

  // Log all products when allProducts changes
  useEffect(() => {
    console.log('All Products:', allProducts);
    if (Array.isArray(allProducts)) {
      setDisplayedProducts(allProducts); // Load initial set of products
    }
  }, [allProducts]);

  const loadMoreProducts = () => {
    if (!Array.isArray(allProducts)) {
      setHasMore(false);
      return;
    }
    const newProducts = allProducts.slice(currentCount, currentCount + productsPerLoad);
    setDisplayedProducts((prevProducts) => [...prevProducts, ...newProducts]);
    setCurrentCount((prevCount) => prevCount + productsPerLoad);

    if (currentCount + productsPerLoad >= allProducts.length) {
      setHasMore(false);
    }
  };

  // Handle scrolling to load more products
  useEffect(() => {
    if (!hasMore) return;

    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 100 >=
        document.documentElement.offsetHeight
      ) {
        loadMoreProducts();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, currentCount, allProducts]);

  // Filter and sort products
  let filteredProducts = displayedProducts.filter(
    (product) => filterCategory === 'All' || product.category === filterCategory
  );

  let sortedProducts = [...filteredProducts];

  switch (sortOption) {
    case 'Price: Low to High':
      sortedProducts.sort((a, b) => a.originalPrice - b.originalPrice);
      break;
    case 'Price: High to Low':
      sortedProducts.sort((a, b) => b.originalPrice - a.originalPrice);
      break;
    case 'Rating':
      sortedProducts.sort((a, b) => (b.ratings || 0) - (a.ratings || 0));
      break;
    default: // 'Newest'
      sortedProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }

  // Extract unique categories
  const categories = Array.isArray(allProducts)
    ? Array.from(new Set(allProducts.map((product) => product.category)))
    : [];

  if (!Array.isArray(allProducts)) {
    return <div className="mosaic-error">No products available.</div>;
  }

  return (
    <div className="mosaic-product-mosaic-container">
      <div className="mosaic-control-panel">
        <select
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
          aria-label="Filter Products by Category"
          className="mosaic-control-select"
        >
          <option value="All">All Categories</option>
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>

        <select
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          aria-label="Sort Products"
          className="mosaic-control-select"
        >
          <option value="Newest">Newest</option>
          <option value="Price: Low to High">Price: Low to High</option>
          <option value="Price: High to Low">Price: High to Low</option>
          <option value="Rating">Rating</option>
        </select>
      </div>

      <div className="mosaic-product-mosaic">
        {sortedProducts.map((product) => (
          <MosaicItem key={product._id} product={product} wishlist={wishlist} dispatch={dispatch} />
        ))}
      </div>

      {hasMore && <div className="mosaic-loading-indicator">Loading...</div>}
    </div>
  );
};

const MosaicItem = ({ product, wishlist, dispatch }) => {
  const isFavorited = wishlist && wishlist.find((item) => item._id === product._id);

  const [showModal, setShowModal] = useState(false);

  // Handle favoriting logic
  const handleFavorite = (e) => {
    e.stopPropagation();
    if (isFavorited) {
      dispatch(removeFromWishlist(product));
      toast.info('Removed from wishlist');
    } else {
      dispatch(addToWishlist(product));
      toast.success('Added to wishlist');
    }
  };

  const handleClick = () => {
    // Open Quick View Modal
    setShowModal(true);
  };

  return (
    <>
      <div
        className="mosaic-mosaic-item"
        onClick={handleClick}
        role="button"
        aria-label={`View details for ${product.name}`}
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter') handleClick();
        }}
      >
        <img
          src={product.images && product.images[0]?.url ? product.images[0].url : '/placeholder-image.jpg'}
          alt={product.name}
          className="mosaic-mosaic-image"
          loading="lazy"
        />
        <div
          className="mosaic-favorite-icon"
          onClick={handleFavorite}
          aria-label={isFavorited ? 'Remove from wishlist' : 'Add to wishlist'}
        >
          {isFavorited ? <AiFillHeart size={22} color="red" /> : <AiOutlineHeart size={22} color="#333" />}
        </div>
        <div className="mosaic-mosaic-overlay">
          <h3 className="mosaic-product-name">{product.name}</h3>
          <p className="mosaic-product-price">${product.originalPrice ? product.originalPrice.toFixed(2) : 'N/A'}</p>
          <div className="mosaic-rating">
            {Array.from({ length: 5 }, (_, i) => (
              <span key={i} className={`mosaic-star ${i < (product.ratings || 0) ? 'filled' : ''}`}>
                ★
              </span>
            ))}
          </div>
        </div>
      </div>

      {showModal && <QuickViewModal product={product} onClose={() => setShowModal(false)} />}
    </>
  );
};

export default ProductInfiniteMosaic;





