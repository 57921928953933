import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { fetchConnections } from '../../redux/actions/connections';
import MiniLoader from '../Layout/MiniLoader';

const FollowingDisplay = React.memo(({ userId, profileType }) => {
  const maxDisplay = 3;
  const emptyDisplay = 1;
  const fallbackImageUrl = 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/1f973de0-c155-4748-9633-76fb5750d800/public'; 
  const dispatch = useDispatch();

  const { following, isLoadingFollowing, error } = useSelector(state => state.connections);

  // Fetch following whenever userId or profileType changes
  useEffect(() => {
    if (userId && profileType) {
      dispatch(fetchConnections(userId, profileType, 'following'));
    }
  }, [userId, profileType, dispatch]);

  // Memoize the following display to prevent unnecessary re-renders
  const displayedFollowing = useMemo(() => {
    return following ? following.slice(0, maxDisplay) : [];
  }, [following]);

  return (
    <div className="followers-avatar-display-container">
      {isLoadingFollowing && (
        <div className="mini-loader-container-profile">
          <MiniLoader />
        </div>
      )}
      {error && <div>Error: {error}</div>}
      {!isLoadingFollowing && (
        <>
          <div className="followers-avatar-display-images">
            {displayedFollowing.length > 0 ? (
              displayedFollowing.map((following, index) => (
                following && following._id ? (
                  <img
                    key={index}
                    className="followers-avatar-display-image"
                    src={following.avatar}
                    alt={following.name}
                  />
                ) : (
                  <div key={index} className="follower-placeholder">No Data</div>
                )
              ))
            ) : (
              <>
                {[...Array(emptyDisplay)].map((_, index) => (
                  <div key={index} className="followers-avatar-display-image empty-avatar">
                    <img
                      src={fallbackImageUrl}
                      alt="No followers yet"
                      className="fallback-avatar-image"
                    />
                    </div>
                ))}
              </>
            )}
          </div>
          <div className="followers-avatar-display-count">
            <span>{following?.length || 0} Following</span>
          </div>
        </>
      )}
    </div>
  );
});

export default FollowingDisplay;

