import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { fetchConnections } from '../../redux/actions/connections';
import MiniLoader from '../Layout/MiniLoader';

const FollowersDisplay = React.memo(({ userId, profileType }) => {
  const maxDisplay = 3;
  const emptyDisplay = 1;
  const fallbackImageUrl = 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/1f973de0-c155-4748-9633-76fb5750d800/public'; 
  const dispatch = useDispatch();

  const { followers, isLoadingFollowers, error } = useSelector(state => state.connections);

  // Fetch followers whenever userId or profileType changes
  useEffect(() => {
    if (userId && profileType) {
      dispatch(fetchConnections(userId, profileType, 'followers'));
    }
  }, [userId, profileType, dispatch]);

  // Memoize the followers display to prevent unnecessary re-renders
  const displayedFollowers = useMemo(() => {
    return followers ? followers.slice(0, maxDisplay) : [];
  }, [followers]);

  return (
    <div className="followers-avatar-display-container">
     {isLoadingFollowers && (
        <div className="mini-loader-container">
          <MiniLoader />
        </div>
      )}
      {error && <div>Error: {error}</div>}
      {!isLoadingFollowers && (
        <>
          <div className="followers-avatar-display-images">
            {displayedFollowers.length > 0 ? (
              displayedFollowers.map((follower, index) => (
                follower && follower._id ? (
                  <img
                    key={index}
                    className="followers-avatar-display-image"
                    src={follower.avatar}
                    alt={follower.name}
                  />
                ) : (
                  <div key={index} className="follower-placeholder">No Data</div>
                )
              ))
            ) : (
              // Display fallback image if no followers
              <>
                {[...Array(emptyDisplay)].map((_, index) => (
                  <div key={index} className="followers-avatar-display-image empty-avatar">
                    <img
                      src={fallbackImageUrl}
                      alt="No followers yet"
                      className="fallback-avatar-image"
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="followers-avatar-display-count">
            <span>{followers?.length || 0} Followers</span>
          </div>
        </>
      )}
    </div>
  );
});

export default FollowersDisplay;








