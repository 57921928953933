import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import { adsServer } from "../../server";

const MobileBarBannerAd = () => {
    const [ads, setAds] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDismissed, setIsDismissed] = useState(false);
    const adRefs = useRef(new Map());

    const handleDismiss = () => {
        setIsDismissed(true);
    };

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${adsServer}/sponsored/click/${adId}`, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                },
            });
            console.log('Click logged for ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const logImpression = async (adId) => {
        try {
            await axios.post(`${adsServer}/sponsored/impression/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                },
            });
            console.log('Impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging impression:', error);
        }
    };

    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${adsServer}/sponsored/viewed-impression/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                },
            });
            console.log('Viewed impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
        }
    };

    useEffect(() => {
        const fetchAds = async () => {
            try {
                const response = await axios.get(`${adsServer}/sponsored/sponsored-products`, { withCredentials: true });
                const activeAds = Array.isArray(response.data)
                    ? response.data.filter(ad => ad.isActive && ad.adSet.images && ad.adSet.images["Banner Ad"])
                    : [];
                if (activeAds.length > 0) {
                    setAds(activeAds);
                    logImpression(activeAds[0]._id);
                } else {
                    console.log("No active ads with Banner Ad images found");
                }
            } catch (error) {
                console.error('Error fetching ads:', error);
            }
        };

        fetchAds();
    }, []);

    useEffect(() => {
        if (ads.length > 0) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            const adId = entry.target.dataset.adId;

                            const timer = setTimeout(() => {
                                logViewedImpression(adId);
                            }, 1000);

                            entry.target._timer = timer;
                        } else {
                            clearTimeout(entry.target._timer); 
                        }
                    });
                },
                { threshold: 0.5 }
            );

            const currentAd = ads[currentIndex];
            const currentAdElement = adRefs.current.get(currentAd?._id);
            if (currentAdElement) {
                observer.observe(currentAdElement);
            }

            return () => {
                observer.disconnect();
            };
        }
    }, [currentIndex, ads]);

    // Auto-slide functionality
    useEffect(() => {
        const autoSlideInterval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % ads.length);
        }, 5000); // Slide every 5 seconds

        return () => clearInterval(autoSlideInterval); // Clean up interval on component unmount
    }, [ads.length]);

    if (!ads.length || isDismissed) return null;

    const currentAd = ads[currentIndex];
    const imageUrl = currentAd.adSet.images["Banner Ad"];
    const position = currentAd.adSet.positions["Banner Ad"] || { x: 0, y: 0 };
    const scale = currentAd.adSet.scales["Banner Ad"] || 1;

    return (
        <div className="mobile-bar-banner-ad-container">
            <div
                className="mobile-bar-banner-ad-content"
                data-ad-id={currentAd?._id}
                ref={(el) => el && adRefs.current.set(currentAd._id, el)}
            >
                <div className="mobile-bar-banner-ad-image-container">
                    <img
                        src={imageUrl}
                        alt={currentAd?.product?.name}
                        className="mobile-bar-banner-ad-image"
                        style={{
                            position: 'absolute',
                            left: `${position.x}%`,
                            top: `${position.y}%`,
                            transform: `scale(${scale})`,
                            transformOrigin: 'center',
                        }}
                    />
                </div>
                <div className="mobile-bar-banner-ad-text">
                    <Link
                        to={`/product/${currentAd?.product?._id}`}
                        onClick={() => logAdClick(currentAd?._id)}
                        className="mobile-bar-banner-ad-link"
                    >
                        <strong>{currentAd?.product?.name}</strong>
                        <span> – Explore Now</span>
                    </Link>
                </div>
                
            </div>
            
        </div>
    );
};

export default MobileBarBannerAd;
