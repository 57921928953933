import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

const HeroSliderMarketplace = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slidesData = [
    {
      id: 1,
      title: "Marketplace",
      buttonText: "Shop Now",
      link: "/marketplace",
      image: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/f32389b1-aa01-4976-489d-f72586731900/public",
    },
    {
      id: 2,
      title: "Discover Quality",
      buttonText: "Explore",
      link: "/marketplace",
      image: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/6b9b29aa-60db-455a-40e3-40bd2e2bc700/public",
    },
    {
      id: 3,
      title: "New Arrivals",
      buttonText: "New Collection",
      link: "/marketplace",
      image: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716384099/buzzvibe4_bsxnps.jpg",
    },
    {
      id: 4,
      title: "Exclusive Offers",
      buttonText: "Get Offers",
      link: "/marketplace",
      image: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716384099/buzzvibe1_l1gfiy.jpg",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 50000);

    return () => clearInterval(interval); 
  }, [currentSlide]); 

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slidesData.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slidesData.length) % slidesData.length);
  };

  return (
    <div className="marketplace-hero-slider">
      {slidesData.map((slide, index) => (
        <div
          key={slide.id}
          className={`marketplace-hero-slide ${index === currentSlide ? 'marketplace-hero-slide-active' : 'marketplace-hero-slide-inactive'}`}
          style={{ backgroundImage: `url(${slide.image})` }}
        >
          {index === currentSlide && (
            <div className="marketplace-hero-slide-content">
              <h1 className="marketplace-hero-slide-title">{slide.title}</h1>
              <Link to={slide.link} className="marketplace-hero-slide-button">
                {slide.buttonText}
              </Link>
            </div>
          )}
        </div>
      ))}
      <button className="marketplace-hero-slider-button marketplace-hero-slider-button-left" onClick={prevSlide}>
        <ChevronLeftIcon className="marketplace-hero-icon" />
      </button>
      <button className="marketplace-hero-slider-button marketplace-hero-slider-button-right" onClick={nextSlide}>
        <ChevronRightIcon className="marketplace-hero-icon" />
      </button>
    </div>
  );
};

export default HeroSliderMarketplace;
