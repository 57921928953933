import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { adsServer } from "../../server";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Layout/Loader';
import AdSetModalCustom from './AdSetModalCustom';
import { FaUpload } from 'react-icons/fa';

const CreateCustomAdCampaign = ({ onSave, adType, details }) => {
    const { seller } = useSelector(state => state.seller);
    const [adText, setAdText] = useState(details?.adText || '');
    const [adLink, setAdLink] = useState(details?.adLink || '');
    const [adImage, setAdImage] = useState(null);
    const [adSpendPerDay, setAdSpendPerDay] = useState(details?.adSpend || 50);
    const [adDuration, setAdDuration] = useState(details?.adDuration || 7);
    const [totalAdSpend, setTotalAdSpend] = useState(adSpendPerDay * adDuration);
    const [isLoading, setIsLoading] = useState(false);
    const [isAdSetModalOpen, setIsAdSetModalOpen] = useState(false); 
    const [adSet, setAdSet] = useState({}); 
    const [isAdSetSaved, setIsAdSetSaved] = useState(false); 
    const [predictedClicks, setPredictedClicks] = useState({ min: 0, max: 0 });
    const [predictedImpressions, setPredictedImpressions] = useState({ min: 0, max: 0 });
    const [displayedPredictedClicks, setDisplayedPredictedClicks] = useState({ min: 0, max: 0 });
    const [displayedPredictedImpressions, setDisplayedPredictedImpressions] = useState({ min: 0, max: 0 });
    const [activeTimeFrame, setActiveTimeFrame] = useState('total');
    const [adImageUrl, setAdImageUrl] = useState(null);
    const [adImageName, setAdImageName] = useState('');
    const [isUploaded, setIsUploaded] = useState(false);

    const fileInputRef = useRef(null);
    const debounceRef = useRef(null);

    const id = seller?._id;

    useEffect(() => {
        setTotalAdSpend(adSpendPerDay * adDuration);
    }, [adSpendPerDay, adDuration]);

    useEffect(() => {
        fetchPredictions(totalAdSpend);
    }, [totalAdSpend]);

    const fetchPredictions = useCallback(async (spend) => {
        if (debounceRef.current) clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(async () => {
            try {
                const { data } = await axios.post(`${adsServer}/sponsored/predict`, { adSpend: spend }, { withCredentials: true });
                if (data) {
                    setPredictedClicks(data.predictedClicks);
                    setPredictedImpressions(data.predictedImpressions);
                    updateDisplayValues('total');
                }
            } catch (error) {
                console.error('Error fetching predictions:', error);
                toast.error('Failed to fetch predictions');
            }
        }, 500);
    }, [adsServer]);

    const updateDisplayValues = (mode) => {
        const factor = mode === 'daily' ? 1 / adDuration : 1;
        setDisplayedPredictedClicks({
            min: Math.round(predictedClicks.min * factor),
            max: Math.round(predictedClicks.max * factor)
        });
        setDisplayedPredictedImpressions({
            min: Math.round(predictedImpressions.min * factor),
            max: Math.round(predictedImpressions.max * factor)
        });
    };

    const handleDisplayModeChange = (mode) => {
        setActiveTimeFrame(mode); 
        updateDisplayValues(mode);
    };

    // URL validation function
    const isValidUrl = (string) => {
        try {
            // Add scheme if not present
            if (!/^(?:http(s)?:\/\/)/i.test(string)) {
                string = `https://${string}`;
            }
            new URL(string);
            return true;
        } catch (e) {
            return false;
        }
    };

    const handleSaveAd = () => {
        // Check if all required fields are filled
        if (!adText || !adLink || !adImage || !adSpendPerDay || !adDuration) {
            toast.error('All fields are required.');
            return;
        }

        // Validate the adLink
        if (!isValidUrl(adLink)) {
            toast.error('Please enter a valid URL for the ad link.');
            return;
        }

        const details = {
            adText,
            adLink: /^https?:\/\//i.test(adLink) ? adLink : `https://${adLink}`, // Ensure adLink has scheme
            adImage: adImageUrl, 
            adSpend: totalAdSpend,
            adDuration,
        };

        onSave(adSet, details, adType);
        toast.success('Custom ad configuration saved.');
    };

    const isSaveEnabled = isAdSetSaved && adImage && adText && adLink;

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const objectUrl = URL.createObjectURL(file);
            setAdImage(file);
            setAdImageUrl(objectUrl);
            setAdImageName(file.name);
            setIsUploaded(true);
        }
    };

    const openAdSetModal = () => {
        setIsAdSetModalOpen(true); 
    };

    const handleAdSetSave = (newAdSet) => {
        setAdSet(newAdSet);
        setIsAdSetSaved(true); 
        setIsAdSetModalOpen(false); 
    };

    useEffect(() => {
        if (adImage) {
            const objectUrl = URL.createObjectURL(adImage);
            setAdImageUrl(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, [adImage]);

    return (
        <div className="create-custom-ad-container">
            <h2>Create a Custom Ad</h2>

            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {/* Ad Text Input */}
                    <div className="form-group">
                        <label htmlFor="adText">Ad Text</label>
                        <input
                            id="adText"
                            type="text"
                            value={adText}
                            onChange={(e) => setAdText(e.target.value)}
                            placeholder="Enter ad text"
                            aria-label="Ad Text"
                        />
                    </div>

                    {/* Ad Link Input */}
                    <div className="form-group">
                        <label htmlFor="adLink">Ad Link</label>
                        <input
                            id="adLink"
                            type="text"
                            value={adLink}
                            onChange={(e) => setAdLink(e.target.value)}
                            placeholder="Enter ad link"
                            aria-label="Ad Link"
                        />
                    </div>

                    {/* Ad Image Upload */}
                    <div 
                        className="form-group ad-preview-upload-icon-wrapper" 
                        onClick={() => fileInputRef.current.click()}
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => { if (e.key === 'Enter') fileInputRef.current.click(); }}
                        aria-label="Upload Ad Image"
                    >
                        <FaUpload
                            className={`ad-preview-upload-icon ${isUploaded ? 'uploaded' : ''}`}
                        />
                        <p className="ad-preview-upload-text">
                            {isUploaded ? `Uploaded Image: ${adImageName}` : 'Upload Custom Image'}
                        </p>
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="ad-preview-upload-input"
                            style={{ display: 'none' }}
                            aria-hidden="true"
                        />
                    </div>

                    {/* Ad Spend Per Day Slider */}
                    <div className="form-group sponsor-product-component-slider">
                        <label htmlFor="adSpendPerDay">Set Ad Spend per Day ($)</label>
                        <div className="slider-input-container">
                            <input
                                id="adSpendPerDay"
                                type="number"
                                value={adSpendPerDay}
                                onChange={(e) => {
                                    const newSpend = Number(e.target.value);
                                    if (newSpend >= 1 && newSpend <= 1000) {
                                        setAdSpendPerDay(newSpend);
                                        setTotalAdSpend(newSpend * adDuration);
                                        fetchPredictions(newSpend * adDuration);
                                    }
                                }}
                                placeholder="Enter amount"
                                aria-label="Ad Spend Per Day"
                                min="1"
                                max="1000"
                            />
                            <input
                                type="range"
                                min="1"
                                max="1000"
                                value={adSpendPerDay}
                                onChange={(e) => {
                                    const newSpend = Number(e.target.value);
                                    setAdSpendPerDay(newSpend);
                                    setTotalAdSpend(newSpend * adDuration);
                                    fetchPredictions(newSpend * adDuration);
                                }}
                                className="sponsor-product-component-range-slider"
                                aria-label="Ad Spend Range Slider"
                            />
                        </div>
                    </div>

                    {/* Ad Duration Slider */}
                    <div className="form-group sponsor-product-component-slider">
                        <label htmlFor="adDuration">Set Ad Duration (Days)</label>
                        <div className="slider-input-container">
                            <input
                                id="adDuration"
                                type="number"
                                value={adDuration}
                                onChange={(e) => {
                                    const newDuration = Number(e.target.value);
                                    if (newDuration >= 1 && newDuration <= 30) {
                                        setAdDuration(newDuration);
                                        setTotalAdSpend(adSpendPerDay * newDuration);
                                        fetchPredictions(adSpendPerDay * newDuration);
                                    }
                                }}
                                placeholder="Enter duration"
                                aria-label="Ad Duration"
                                min="1"
                                max="30"
                            />
                            <input
                                type="range"
                                min="1"
                                max="30"
                                value={adDuration}
                                onChange={(e) => {
                                    const newDuration = Number(e.target.value);
                                    setAdDuration(newDuration);
                                    setTotalAdSpend(adSpendPerDay * newDuration);
                                    fetchPredictions(adSpendPerDay * newDuration);
                                }}
                                className="sponsor-product-component-range-slider"
                                aria-label="Ad Duration Range Slider"
                            />
                        </div>
                    </div>

                    {/* Total Ad Spend Display */}
                    <div className="form-group sponsor-product-component-total-spend">
                        <label htmlFor="totalAdSpend">Total Ad Spend: ${totalAdSpend}</label>
                    </div>

                    {/* Display Mode Buttons */}
                    <div className="form-group sponsor-product-component-display-mode">
                        <button
                            type="button"
                            onClick={() => handleDisplayModeChange('daily')}
                            className={`time-button ${activeTimeFrame === 'daily' ? 'active-time-button' : ''}`}
                            aria-pressed={activeTimeFrame === 'daily'}
                            aria-label="Display Mode Daily"
                        >
                            Daily
                        </button>
                        <button
                            type="button"
                            onClick={() => handleDisplayModeChange('total')}
                            className={`time-button ${activeTimeFrame === 'total' ? 'active-time-button' : ''}`}
                            aria-pressed={activeTimeFrame === 'total'}
                            aria-label="Display Mode Total"
                        >
                            Total
                        </button>
                    </div>

                    {/* Predictions Display */}
                    <div className="form-group sponsor-product-component-predictions">
                        {displayedPredictedClicks.min !== 0 || displayedPredictedClicks.max !== 0 ? (
                            <>
                                <div className="sponsor-product-component-prediction-item">
                                    <label>Predicted Clicks Range:</label>
                                    <span>{displayedPredictedClicks.min} to {displayedPredictedClicks.max}</span>
                                </div>
                                <div className="sponsor-product-component-prediction-item">
                                    <label>Predicted Impressions Range:</label>
                                    <span>{displayedPredictedImpressions.min} to {displayedPredictedImpressions.max}</span>
                                </div>
                            </>
                        ) : (
                            <div className="sponsor-product-component-null-message">
                                Please choose your ad spend/time duration
                            </div>
                        )}
                    </div>

                    {/* Customize Ad Set Button */}
                    <div className="form-group customize-ad-set-button-container">
                        <button 
                            type="button" 
                            onClick={openAdSetModal} 
                            className={`customize-ad-set-button ${!adImage ? 'disabled' : ''}`}
                            disabled={!adImage} 
                            aria-label="Customize Ad Set"
                        >
                            Customize Ad Set
                        </button>
                    </div>

                    {/* Save Ad Configuration Button */}
                    <button 
                        type="button" 
                        className={`sponsor-product-component-submit-button ${isSaveEnabled ? 'enabled' : 'disabled'}`} 
                        onClick={isSaveEnabled ? handleSaveAd : null}
                        disabled={!isSaveEnabled}
                        aria-label="Save Ad Configuration"
                    >
                        Save Ad Configuration
                    </button>

                    {/* Ad Set Modal */}
                    <AdSetModalCustom
                        isOpen={isAdSetModalOpen}
                        onClose={() => setIsAdSetModalOpen(false)}
                        onSave={handleAdSetSave}
                        adFormats={[
                            'Top Banner Ad', 
                            'Banner Ad', 
                            'Sponsored Products', 
                            'Dual Carousel', 
                            'Quad Carousel', 
                            'Sponsored Product Carousel', 
                            'Sponsored Feed Ads', 
                            'Map Ad Slider'
                        ]}
                        image={adImage} 
                        adText={adText}
                    />
                </>
            )}
        </div>
    );

};

export default CreateCustomAdCampaign;
