import React from 'react';

export default function FeaturedShop() {
  return (
    <div className="featured-shop-bg-black">
      <div className="featured-shop-container">
        <div className="featured-shop-relative-container">
          <div className="featured-shop-image-wrapper">
            <img
              src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1718108735/banners/hd2iauw5ydeyhpgbsg10.jpg"
              alt="Amsterdam Collection"
              className="featured-shop-image"
            />
          </div>
          <div aria-hidden="true" className="featured-shop-hidden-lg" />
          <div aria-hidden="true" className="featured-shop-hidden-sm" />
          <div className="featured-shop-absolute-container">
            <div>
              <h2 className="featured-shop-title">Amsterdam Collection</h2>
              <p className="featured-shop-subtitle">
                Amsterdam style meets quality
              </p>
            </div>
            <a
              href="#"
              className="featured-shop-button"
            >
              View the collection
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

  