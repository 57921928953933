import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { getAllEventsShop } from "../../redux/actions/event";
import ProductCard from "../Route/ProductCard/ProductCard";
import Ratings from "../Products/Ratings";
import PostsWidgetRedux from "../../widgets/PostsWidgetRedux";
import MyPostWidgetRedux from "../../widgets/MyPostWidgetRedux";
import FriendListWidget from "../../widgets/FriendListWidget";
import axios from "axios";
import { server } from "../../server";
import IndividualShopProducts from "./IndividualShopProducts";
import ConnectionsWidget from "../Followers/ConnectionsWidget";
import { DocumentTextIcon, ShoppingBagIcon, CalendarIcon, UsersIcon, StarIcon, InformationCircleIcon, PhotoIcon } from '@heroicons/react/24/outline';
import ShopAbout from "./ShopAbout";
import OtherConnectionsWidget from "../Connections/OtherConnectionsWidget";
import MyShopImagesProfile from "../Content/MyShopImagesProfile";


const ShopProfileData = ({ isOwner }) => {
  const { products } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const { events } = useSelector((state) => state.events);
  const { handle } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const picturePath = user?.picturePath;
  const _id = user?._id;
  const [shopId, setShopId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${server}/shop/handle-to-id/${handle}`)
      .then(res => {
        const id = res.data.id;
        setShopId(id);
        setIsLoading(false);
        dispatch(getAllProductsShop(id));
        dispatch(getAllEventsShop(id));
      })
      .catch(error => console.log(error));
      setIsLoading(false);
  }, [handle, dispatch]);

  const [activeTab, setActiveTab] = useState('Posts');

  const tabs = [
    { name: 'Posts', icon: DocumentTextIcon, current: activeTab === 'Posts' },
    { name: 'Products', icon: ShoppingBagIcon, current: activeTab === 'Products' },
    { name: 'Images', icon: PhotoIcon, current: activeTab === 'Images' },
    { name: 'Sales', icon: CalendarIcon, current: activeTab === 'Running Events' },
    { name: 'Connections', icon: UsersIcon, current: activeTab === 'Connections' },
    { name: 'Reviews', icon: StarIcon, current: activeTab === 'Reviews' },
    { name: 'About', icon: InformationCircleIcon, current: activeTab === 'About' },
  ];



  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const allReviews =
    products && products.map((product) => product.reviews).flat();

     
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="shop-profile-data-content-container">
      {/* Tab navigation */}
      <div className="shop-profile-data-tab-navigation">
        <nav className="shop-profile-data-tabs" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => setActiveTab(tab.name)}
              className={classNames(
                tab.current ? 'shop-profile-data-tab-active' : 'shop-profile-data-tab-inactive',
                'shop-profile-data-tab-button'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              <tab.icon className="shop-profile-data-tab-icon" />
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      {activeTab === 'Posts' && (
        <div className="shop-profile-data-tab-content">
          
          <PostsWidgetRedux ownerId={shopId} profileType="Shop" isProfile={true} />
        </div>
      )}

      {activeTab === 'Products' && (
        <IndividualShopProducts shopId={shopId} isOwner={isOwner} />
      )}

    {activeTab === 'Images' && (
        <div className="shop-profile-data-tab-content">
          <MyShopImagesProfile shopId={shopId} />
        </div>
      )}

      {activeTab === 'Running Events' && (
        <div className="shop-profile-data-tab-content">
          <div className="shop-profile-data-events-grid">
            {events &&
              events.map((event, index) => (
                <ProductCard
                  data={event}
                  key={index}
                  isShop={true}
                  isEvent={true}
                />
              ))}
          </div>
          {events && events.length === 0 && (
            <h5 className="shop-profile-data-no-events">No Events found for this shop!</h5>
          )}
        </div>
      )}
      {activeTab === 'Connections' && (
        <div className="shop-profile-data-tab-content">
          
          <OtherConnectionsWidget userId={shopId} profileType="Shop" />
        </div>
      )}

      {activeTab === 'Reviews' && (
        <div className="shop-profile-data-tab-content">
          {allReviews &&
            allReviews.map((review, index) => (
              <div className="shop-profile-data-review" key={index}>
                <img
                  src={review.user.avatar?.url}
                  className="shop-profile-data-review-avatar"
                  alt=""
                />
                <div className="shop-profile-data-review-content">
                  <div className="shop-profile-data-review-header">
                    <h1 className="shop-profile-data-review-user">{review.user.name}</h1>
                    <Ratings rating={review.rating} />
                  </div>
                  <p className="shop-profile-data-review-comment">{review.comment}</p>
                  <p className="shop-profile-data-review-date">{"2 days ago"}</p>
                </div>
              </div>
            ))}
          {allReviews && allReviews.length === 0 && (
            <h5 className="shop-profile-data-no-reviews">No Reviews found for this shop!</h5>
          )}
        </div>
      )}
       {activeTab === 'About' && (
        <div className="shop-profile-data-tab-content">
          <ShopAbout shopData={seller} isOwner={isOwner} />
        </div>
      )}

      {isOwner && (
        <Link
          to={`/dashboard/${handle}`}
          className="shop-profile-data-dashboard-link"
        >
          Go to Dashboard
        </Link>
      )}
    </div>
  );
};

export default ShopProfileData;

