import React from "react";
import { FaCheck } from "react-icons/fa";

const OnboardingStepsMobile = ({ currentStep }) => {
  const steps = [
    { id: 1, label: "Create Shop" },
    { id: 2, label: "Verify Email" },
    { id: 3, label: "Complete Onboarding" },
    { id: 4, label: "Payment Method Setup" },
    { id: 5, label: "Choose Subscription" },
  ];

  return (
    <div className="onboarding-steps-mobile">
      <div className="onboarding-steps-mobile-header">
        Step {currentStep} of {steps.length}
      </div>
      <ol className="onboarding-steps-mobile-list">
        {steps.map((step) => (
          <li key={step.id} className="onboarding-steps-mobile-item">
            <div
              className={`onboarding-steps-mobile-circle ${
                currentStep > step.id
                  ? "completed"
                  : currentStep === step.id
                  ? "current"
                  : "upcoming"
              }`}
            >
              {currentStep > step.id ? <FaCheck /> : step.id}
            </div>
            <div className="onboarding-steps-mobile-label">{step.label}</div>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default OnboardingStepsMobile;