import React from 'react';

const CategoryScroller = ({ categoriesData, onCategorySelect }) => {
  return (
    <div className="category-scroller-container">
      <div className="category-scroller">
        {categoriesData.map((category) => (
          <div
            className="category-item"
            key={category.id}
            onClick={() => onCategorySelect(category)}
          >
            <div className="category-image-wrapper">
              <img src={category.imageUrl} alt={category.title} className="category-image" />
            </div>
            <p className="category-name">{category.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryScroller;
