import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { server } from '../../server'; 


const Rooms = ({ profileType, profileId }) => {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchRooms = async () => {
      if (!profileType || !profileId) {
        setError('Profile type and ID are required to fetch rooms.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${server}/room/profile-rooms`, {
          params: {
            profileType,
            profileId,
          },
          withCredentials: true,
        });

        if (response.data.success) {
          setRooms(response.data.rooms);
        } else {
          setError(response.data.message || 'Failed to fetch rooms.');
        }
        setLoading(false);
      } catch (err) {
        console.error("Error fetching rooms:", err);
        setError('Failed to fetch rooms.');
        setLoading(false);
      }
    };

    fetchRooms();
  }, [profileType, profileId]);

  if (loading) return <p>Loading rooms...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="rooms-container">
      <h2>Rooms</h2>
      {rooms.length > 0 ? (
        <ul className="rooms-list">
          {rooms.map(room => (
            <Link to={`/rooms/${room.handle}`} key={room._id} className="rooms-list-item-link">
              <li 
                className="rooms-list-item" 
                style={{
                  backgroundImage: `url(${room.banner?.url || ''})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}>
                <div className="room-name">{room.name}</div>
                <div className="room-description">{room.description}</div>
              </li>
            </Link>
          ))}
        </ul>
      ) : (
        <p>No rooms created yet.</p>
      )}
    </div>
  );
};

export default Rooms;




