import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function ThreeTileGrid({ products, shopImages }) {
  // State for large tile carousel
  const [currentShopImage, setCurrentShopImage] = useState(0);

  // State for products carousel
  const [currentProductSlide, setCurrentProductSlide] = useState(0);

  // Ensure `shopImages` and `products` are valid arrays
  const validShopImages = Array.isArray(shopImages) && shopImages.length > 0 ? shopImages : [];
  const validProducts = Array.isArray(products) && products.length > 0 ? products : [];

  const totalShopImages = validShopImages.length;
  const totalProducts = validProducts.length;

  const nextShopImage = () => {
    setCurrentShopImage((prevImage) => (prevImage + 1) % totalShopImages);
  };

  const prevShopImage = () => {
    setCurrentShopImage((prevImage) => (prevImage - 1 + totalShopImages) % totalShopImages);
  };

  const itemsPerSlide = 4; // We need to display 4 products now

  const nextProductSlide = () => {
    setCurrentProductSlide((prevSlide) =>
      (prevSlide + itemsPerSlide) % totalProducts
    );
  };

  const prevProductSlide = () => {
    setCurrentProductSlide((prevSlide) =>
      (prevSlide - itemsPerSlide + totalProducts) % totalProducts
    );
  };

  if (!validShopImages.length && !validProducts.length) {
    return <div>No images or products to display.</div>;
  }

  // Get current products to display
  let currentProducts = validProducts.slice(currentProductSlide, currentProductSlide + itemsPerSlide);

  // If less than itemsPerSlide remain, append from the start
  if (currentProducts.length < itemsPerSlide) {
    currentProducts = currentProducts.concat(validProducts.slice(0, itemsPerSlide - currentProducts.length));
  }

  return (
    <div className="three-tile-bg-black">
      <div className="three-tile-container">
        <div className="three-tile-header-container">
          <h2 className="three-tile-heading">BuzzVibe Boutique</h2>
          <a href="/shop/buzzvibeboutique" className="three-tile-browse-link">
            Browse all products
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>

        <div className="three-tile-carousel">
          <div className="three-tile-grid">
            {/* Large Tile */}
            {totalShopImages > 0 && (
              <div className="three-tile-item large">
                <a href="/shop/buzzvibeboutique">
                  {validShopImages[currentShopImage]?.url ? (
                    <img
                      src={validShopImages[currentShopImage].url}
                      alt={validShopImages[currentShopImage].alt || 'BuzzVibe Boutique'}
                      className="three-tile-large-image"
                    />
                  ) : (
                    <div className="three-tile-placeholder">No Image Available</div>
                  )}
                  <div className="three-tile-overlay" />
                  <div className="three-tile-text">
                    <h3>Visit BuzzVibe Boutique</h3>
                    <p>Explore our collection</p>
                  </div>
                </a>
                {/* Large Tile Carousel Controls */}
                {totalShopImages > 1 && (
                  <>
                    <button className="three-tile-carousel-button prev" onClick={prevShopImage} aria-label="Previous Shop Image">
                      &#10094;
                    </button>
                    <button className="three-tile-carousel-button next" onClick={nextShopImage} aria-label="Next Shop Image">
                      &#10095;
                    </button>
                  </>
                )}
              </div>
            )}

            {/* Small Tiles Container */}
            <div className="small-tiles-container">
              {currentProducts.map((product) => (
                <div className="three-tile-item small" key={product._id}>
                  <a href={`/product/${product._id}`}>
                    {product.images[0]?.url ? (
                      <img
                        src={product.images[0].url}
                        alt={product.name}
                        className="three-tile-image"
                      />
                    ) : (
                      <div className="three-tile-placeholder">No Image Available</div>
                    )}
                    <div className="three-tile-overlay" />
                    <div className="three-tile-text">
                      <h3>{product.name}</h3>
                      <p>Shop now</p>
                    </div>
                  </a>
                </div>
              ))}
              {/* Product Carousel Controls */}
              {totalProducts > itemsPerSlide && (
                <>
                  <button className="three-tile-carousel-button product-prev" onClick={prevProductSlide} aria-label="Previous Products">
                    &#10094;
                  </button>
                  <button className="three-tile-carousel-button product-next" onClick={nextProductSlide} aria-label="Next Products">
                    &#10095;
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ThreeTileGrid.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  shopImages: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    })
  ).isRequired,
};


